import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import "./assets/css/Clock.css"
import "./assets/css/Demo.css"
import "./assets/css/TimePicker.css"
import "./assets/css/TimeRangePicker.css"
import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";

//redux-translation
import { Provider } from "react-redux";
import { IntlProvider } from "react-redux-multilingual";
import store from "./store";
import translations from "./constants/translations";

ReactDOM.render(
  <Provider store={store}>
    <IntlProvider translations={translations} locale="en">
      <BrowserRouter>
        <Switch>
          <Route path="/admin" render={(props) =><AdminLayout {...props} />} />
          <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
          <Redirect from="/" to="/auth/login" />
        </Switch>
      </BrowserRouter>
    </IntlProvider>
  </Provider>,
  document.getElementById("root")
);
