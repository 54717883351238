import {
  FETCH_DOCTORS_START,
  FETCH_DOCTORS_SUCCESS,
  FETCH_DOCTORS_FAILURE,
  UPDATE_DOCTORS_START,
  UPDATE_DOCTORS_SUCCESS,
  UPDATE_DOCTORS_FAILURE,
  CREATE_DOCTORS_START,
  CREATE_DOCTORS_SUCCESS,
  CREATE_DOCTORS_FAILURE,
  ME_DOCTOR_FAILURE,
  ME_DOCTOR_SUCCESS,
  ME_DOCTOR_START,
} from "../constants/ActionTypes";

const initialState = {};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_DOCTORS_START:
      return Object.assign({}, state, action.payload);
    case FETCH_DOCTORS_SUCCESS:
      return Object.assign({}, state, action.payload);
    case FETCH_DOCTORS_FAILURE:
      return Object.assign({}, state, action.payload);
    case UPDATE_DOCTORS_START:
      return Object.assign({}, state, action.payload);
    case UPDATE_DOCTORS_SUCCESS:
      return Object.assign({}, state, action.payload);
    case UPDATE_DOCTORS_FAILURE:
      return Object.assign({}, state, action.payload);
    case CREATE_DOCTORS_START:
      return Object.assign({}, state, action.payload);
    case CREATE_DOCTORS_SUCCESS:
      return Object.assign({}, state, action.payload);
    case CREATE_DOCTORS_FAILURE:
      return Object.assign({}, state, action.payload);
    case ME_DOCTOR_FAILURE:
      return Object.assign({}, state, action.payload);
    case ME_DOCTOR_SUCCESS:
      return Object.assign({}, state, action.payload);
    case ME_DOCTOR_START:
      return Object.assign({}, state, action.payload);
    default:
  }
  return state;
}
