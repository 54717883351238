import * as types from "../constants/ActionTypes";
import * as api from "../constants/ReUsageStaff";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
toast.configure();

export const loginStart = () => ({
  type: types.USER_LOGIN_START,
  payload: {
    loginLoading: true,
    isLoggedin: false,
  },
});

export const loginSuccess = (authInfo) => ({
  type: types.USER_LOGIN_SUCCESS,
  payload: {
    authInfo: authInfo,
    isLoggedIn: true,
    loginLoading: false,
  },
});

export const loginFailure = (error) => ({
  type: types.USER_LOGOUT_SUCCESS,
  payload: {
    isLoggedIn: false,
    authInfo: null,
    loginLoading: false,
  },
});

export const logout = (history) => (dispatch) => {
  dispatch(loginFailure());
  history.push("/auth/login");
};

export const LoginAll = (loginObject, history) => (dispatch) => {
  dispatch(loginStart());
  const formData = new FormData();
  formData.append("username", loginObject.email);
  formData.append("password", loginObject.password);
  const requestOptions = {
    method: "POST",
    body: formData,
  };

  fetch(`${api.login_url}`, requestOptions)
    .then((response) => {
      const statusCode = response.status;
      const data = response.json();
      return Promise.all([statusCode, data]);
    })
    .then(([statusCode, data]) => {
      if (statusCode === 200) {
        toast.success("Login success!");
        dispatch(loginSuccess(data));
        history.push("/admin/index");
      } else if (statusCode === 422) {
        toast.error("given data invalid!");
        dispatch(loginFailure("There are some missing fields"));
      } else if (data.detail === "Incorrect email or password") {
        toast.error("Incorrect email or password");
        dispatch(loginFailure(data.detail));
      } else if (statusCode === 401) {
        toast.error("unauthorized!");
        dispatch(loginFailure(statusCode));
      } else if (data.detail) {
        toast.error(data.detail);
        dispatch(loginFailure(data.detail));
      } else {
        toast.error("unauthorized!");
        dispatch(loginFailure("Server error!"));
      }
    })
    .catch((error) => {
      toast.error("something went wrong, Try later!");
      dispatch(loginFailure(error));
    });
};

//user dasboard
export const userStart = () => ({
  type: types.USER_ME_START,
  payload: {
    userLoading: true,
  },
});

export const userSuccess = (data) => ({
  type: types.USER_ME_SUCCESS,
  payload: {
    userInfo: data,
    userLoading: true,
  },
});

export const userFailure = (error) => ({
  type: types.USER_ME_FAILURE,
  payload: {
    userLoading: true,
  },
});

export const userInfo = (authInfo, history) => (dispatch) => {
  dispatch(userStart());
  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");
  headers.append("Authorization", "Bearer " + authInfo.access_token);
  headers.append("Origin", "*");
  const requestOptions = {
    method: "GET",
    headers: headers,
  };

  fetch(`${api.user_info_url}`, requestOptions)
    .then((response) => {
      const statusCode = response.status;
      const data = response.json();
      return Promise.all([statusCode, data]);
    })
    .then(([statusCode, data]) => {
      if (statusCode === 200) {
        dispatch(userSuccess(data));
      } else if (statusCode === 401) {
        toast.error("unauthorized!");
        dispatch(userFailure(statusCode));
        dispatch(logout(history));
      } else if (data.detail) {
        toast.error(data.detail);
        dispatch(userFailure(data.detail));
      } else {
        dispatch(userFailure("Server error!"));
      }
    })
    .catch((error) => {
      toast.error("something went wrong, Try later!");
      dispatch(userFailure(error));
      dispatch(logout(history));
    });
};

//image upload
export const ImageStart = () => ({
  type: types.HOSPITAL_IMAGE_START,
  payload: {
    imageLoading: true,
  },
});

export const ImageSuccess = (Image) => ({
  type: types.HOSPITAL_IMAGE_SUCCESS,
  payload: {
    imageLoading: false,
    Image: Image,
  },
});

export const ImageFailure = (error) => ({
  type: types.HOSPITAL_IMAGE_FAILURE,
  payload: {
    imageLoading: true,
  },

  error,
});

export const ImageUpload = (image, authInfo, history, type) => (dispatch) => {
  let headers = new Headers();
  // headers.append("Content-Type", "multipart/form-data");
  headers.append("Accept", "application/json");
  headers.append("Authorization", "Bearer " + authInfo.access_token);
  headers.append("Origin", "*");
  const formData = new FormData();
  formData.append("file", image);
  const requestOptions = {
    method: "POST",
    headers: headers,
    body: formData,
  };
  let url = "";
  if (type == "hos") {
    url = api.hos_image_upload_url;
  }
  if (type == "doc") {
    url = api.doc_image_upload_url;
  }
  fetch(url, requestOptions)
    .then((response) => {
      const statusCode = response.status;
      const data = response.json();
      return Promise.all([statusCode, data]);
    })
    .then(([statusCode, data]) => {
      if (statusCode === 200) {
        dispatch(ImageSuccess(data));
        // toast.success("Image upload successfully!");
      } else if (statusCode === 401) {
        toast.error("unauthorized!");
        dispatch(logout(history));
        dispatch(ImageFailure(statusCode));
      } else if (data.detail) {
        toast.error(data.detail);
        dispatch(ImageFailure(data.detail));
      } else {
        dispatch(ImageFailure("Server error!"));
      }
    })
    .catch((error) => {
      toast.error("something went wrong, Try later!");

      dispatch(ImageFailure(error));
    });
};
