import {
  LINE_SEND_START,
  LINE_SEND_SUCCESS,
  LINE_SEND_FAILURE,
} from "../constants/ActionTypes";

const initialState = {};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case LINE_SEND_START:
      return Object.assign({}, state, action.payload);
    case LINE_SEND_SUCCESS:
      return Object.assign({}, state, action.payload);
    case LINE_SEND_FAILURE:
      return Object.assign({}, state, action.payload);
    default:
  }
  return state;
}
