// auth types
export const USER_LOGIN_START = "USER_LOGIN_START";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";

//fetch hospitals
export const FETCH_HOSPITALS_START = "FETCH_HOSPITALS_START";
export const FETCH_HOSPITALS_SUCCESS = "FETCH_HOSPITALS_SUCCESS";
export const FETCH_HOSPITALS_FAILURE = "FETCH_HOSPITALS_FAILURE";

//hospital info
export const US_HOSPITALS_START = "US_HOSPITALS_START";
export const US_HOSPITALS_SUCCESS = "US_HOSPITALS_SUCCESS";
export const US_HOSPITALS_FAILURE = "US_HOSPITALS_FAILURE";

//modify hospitals
export const UPDATE_HOSPITALS_START = "UPDATE_HOSPITALS_START";
export const UPDATE_HOSPITALS_SUCCESS = "UPDATE_HOSPITALS_SUCCESS";
export const UPDATE_HOSPITALS_FAILURE = "UPDATE_HOSPITALS_FAILURE";

//create hospitals
export const CREATE_HOSPITALS_START = "CREATE_HOSPITALS_START";
export const CREATE_HOSPITALS_SUCCESS = "CREATE_HOSPITALS_SUCCESS";
export const CREATE_HOSPITALS_FAILURE = "CREATE_HOSPITALS_FAILURE";

//user info
export const USER_ME_START = "USER_ME_START";
export const USER_ME_SUCCESS = "USER_ME_SUCCESS";
export const USER_ME_FAILURE = "USER_ME_FAILURE";

//fetch doctors
export const FETCH_DOCTORS_START = "FETCH_DOCTORS_START";
export const FETCH_DOCTORS_SUCCESS = "FETCH_DOCTORS_SUCCESS";
export const FETCH_DOCTORS_FAILURE = "FETCH_DOCTORS_FAILURE";

//modify doctors
export const UPDATE_DOCTORS_START = "UPDATE_DOCTORS_START";
export const UPDATE_DOCTORS_SUCCESS = "UPDATE_DOCTORS_SUCCESS";
export const UPDATE_DOCTORS_FAILURE = "UPDATE_DOCTORS_FAILURE";

//create doctors
export const CREATE_DOCTORS_START = "CREATE_DOCTORS_START";
export const CREATE_DOCTORS_SUCCESS = "CREATE_DOCTORS_SUCCESS";
export const CREATE_DOCTORS_FAILURE = "CREATE_DOCTORS_FAILURE";

//fetch groups
export const FETCH_GROUPS_START = "FETCH_GROUPS_START";
export const FETCH_GROUPS_SUCCESS = "FETCH_GROUPS_SUCCESS";
export const FETCH_GROUPS_FAILURE = "FETCH_GROUPS_FAILURE";

//modify groups
export const UPDATE_GROUPS_START = "UPDATE_GROUPS_START";
export const UPDATE_GROUPS_SUCCESS = "UPDATE_GROUPS_SUCCESS";
export const UPDATE_GROUPS_FAILURE = "UPDATE_GROUPS_FAILURE";

//create groups
export const CREATE_GROUPS_START = "CREATE_GROUPS_START";
export const CREATE_GROUPS_SUCCESS = "CREATE_GROUPS_SUCCESS";
export const CREATE_GROUPS_FAILURE = "CREATE_GROUPS_FAILURE";

//delete groups
export const DELETE_GROUPS_START = "DELETE_GROUPS_START";
export const DELETE_GROUPS_SUCCESS = "DELETE_GROUPS_SUCCESS";
export const DELETE_GROUPS_FAILURE = "DELETE_GROUPS_FAILURE";

//fetch group members
export const FETCH_GROUPS_MEMBERS_START = "FETCH_GROUPS_MEMBERS_START";
export const FETCH_GROUPS_MEMBERS_SUCCESS = "FETCH_GROUPS_MEMBERS_SUCCESS";
export const FETCH_GROUPS_MEMBERS_FAILURE = "FETCH_GROUPS_MEMBERS_FAILURE";

//update group members
export const UPDATE_GROUPS_MEMBERS_START = "UPDATE_GROUPS_MEMBERS_START";
export const UPDATE_GROUPS_MEMBERS_SUCCESS = "UPDATE_GROUPS_MEMBERS_SUCCESS";
export const UPDATE_GROUPS_MEMBERS_FAILURE = "UPDATE_GROUPS_MEMBERS_FAILURE";

//fetch members not in group
export const FETCH_NOT_GROUPS_MEMBERS_START = "FETCH_NOT_GROUPS_MEMBERS_START";
export const FETCH_NOT_GROUPS_MEMBERS_SUCCESS =
  "FETCH_NOT_GROUPS_MEMBERS_SUCCESS";
export const FETCH_NOT_GROUPS_MEMBERS_FAILURE =
  "FETCH_NOT_GROUPS_MEMBERS_FAILURE";

//line send
export const LINE_SEND_START = "LINE_SEND_START";
export const LINE_SEND_SUCCESS = "LINE_SEND_SUCCESS";
export const LINE_SEND_FAILURE = "LINE_SEND_FAILURE";

//fetch invoice list
export const FETCH_INVOCE_LIST_START = "FETCH_INVOCE_LIST_START";
export const FETCH_INVOCE_LIST_SUCCESS = "FETCH_INVOCE_LIST_SUCCESS";
export const FETCH_INVOCE_LIST_FAILURE = "FETCH_INVOCE_LIST_FAILURE";
//fetch invoice view list
export const FETCH_INVOCE_LIST_LIST_SUCCESS = "FETCH_INVOCE_LIST_LIST_SUCCESS";

//invoice doc list
export const INVOICE_DOC_LIST_START = "INVOICE_DOC_LIST_START";
export const INVOICE_DOC_LIST_SUCCESS = "INVOICE_DOC_LIST_SUCCESS";
export const INVOICE_DOC_LIST_FAILURE = "INVOICE_DOC_LIST_FAILURE";

//invoice pdf
export const INVOCE_PDF_START = "INVOCE_PDF_START";
export const INVOCE_PDF_SUCCESS = "INVOCE_PDF_SUCCESS";
export const INVOCE_PDF_FAILURE = "INVOCE_PDF_FAILURE";

//image upload
export const HOSPITAL_IMAGE_START = "HOSPITAL_IMAGE_START";
export const HOSPITAL_IMAGE_SUCCESS = "HOSPITAL_IMAGE_SUCCESS";
export const HOSPITAL_IMAGE_FAILURE = "HOSPITAL_IMAGE_FAILURE";

//get single doctor
export const ME_DOCTOR_START = "ME_DOCTOR_START";
export const ME_DOCTOR_SUCCESS = "ME_DOCTOR_SUCCESS";
export const ME_DOCTOR_FAILURE = "ME_DOCTOR_FAILURE";

//get single hospital
export const FETCH_SINGLE_HOSPITAL_START = "FETCH_SINGLE_HOSPITAL_START";
export const FETCH_SINGLE_HOSPITAL_SUCCESS = "FETCH_SINGLE_HOSPITAL_SUCCESS";
export const FETCH_SINGLE_HOSPITAL_FAILURE = "FETCH_SINGLE_HOSPITAL_FAILURE";
