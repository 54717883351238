import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslate } from "react-redux-multilingual";
import { userInfo } from "../../actions/userActions";
import { doctorUpdate } from "../../actions/doctorActions";
import { validateEmail, validateNull } from "../../services/validation";
import { toast } from "react-toastify";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfoData: null,
      password: "",
    };
  }
  componentWillMount() {
    this.props.dispatch(userInfo(this.props.authInfo, this.props.history));
  }
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }
  componentWillReceiveProps(newProps) {
    if (this.state.userInfoData != newProps.userInfo) {
      this.setState({
        id: newProps.userInfo.id,
        email: newProps.userInfo.email,
        last_name: newProps.userInfo.last_name,
        last_name_yomigana: newProps.userInfo.last_name_yomigana,
        first_name: newProps.userInfo.first_name,
        first_name_yomigana: newProps.userInfo.first_name_yomigana,
        date_of_birth: newProps.userInfo.date_of_birth,
        phone: newProps.userInfo.phone,
        sex: newProps.userInfo.sex,
        password: "",
      });
    }
  }
  handleSubmit(e) {
    e.preventDefault();
    const { translate } = this.props;
    let data = {
      email: this.state.email,
      last_name: this.state.last_name,
      last_name_yomigana: this.state.last_name_yomigana,
      first_name: this.state.first_name,
      first_name_yomigana: this.state.first_name_yomigana,
      date_of_birth: this.state.date_of_birth,
      phone: this.state.phone,
      sex: this.state.sex,
      // password: this.state.password.length > 0 ? this.state.password :
    };
    if (
      validateEmail(this.state.email) &&
      validateNull(this.state.last_name) &&
      validateNull(this.state.first_name) &&
      validateNull(this.state.phone)
    ) {
      this.props.dispatch(
        doctorUpdate(
          data,
          this.props.authInfo,
          this.props.history,
          this.state.id
        )
      );
    } else {
      if (validateEmail(this.state.email) === false) {
        toast.error(translate("Invalid_email_address"));
      }
      if (validateNull(this.state.last_name) === false) {
        toast.error(translate("missing_field") + " Last Name");
      }
      if (validateNull(this.state.first_name) === false) {
        toast.error(translate("missing_field") + " First Name");
      }
      if (validateNull(this.state.phone) === false) {
        toast.error(translate("missing_field") + " Mobile Number");
      }
    }
  }
  render() {
    const { translate } = this.props;
    return (
      <>
        <div className="pb-6 pt-2 pt-md-8"></div>

        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">{translate("My_account")}</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      {/* <Button
                        color="primary"
                        href="#pablo"
                        onClick={(e) => this.handleSubmit(e)}
                        size="sm"
                      >
                        update
                      </Button> */}
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      {translate("User_information")}
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              {translate("Email_address")}
                            </label>
                            <Input
                              className="form-control-alternative"
                              name="email"
                              value={this.state.email}
                              onChange={(e) => this.handleChange(e)}
                              id="input-username"
                              placeholder={translate("Email_address")}
                              type="text"
                              readOnly
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              {translate("Password")}
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-first-name"
                              placeholder={translate("Password")}
                              type="password"
                              name="password"
                              value={this.state.password}
                              onChange={(e) => this.handleChange(e)}
                              readOnly
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              {translate("last_name")}
                            </label>
                            <Input
                              // defaultValue={
                              //   this.props.userInfo
                              //     ? this.props.userInfo.last_name
                              //     : null
                              // }
                              className="form-control-alternative"
                              id="input-email"
                              placeholder={translate("last_name")}
                              type="email"
                              name="last_name"
                              value={this.state.last_name}
                              onChange={(e) => this.handleChange(e)}
                              readOnly
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              {translate("last_name_y")}
                            </label>
                            <Input
                              className="form-control-alternative"
                              // defaultValue={
                              //   this.props.userInfo
                              //     ? this.props.userInfo.last_name_yomigana
                              //     : null
                              // }
                              name="last_name_yomigana"
                              value={this.state.last_name_yomigana}
                              onChange={(e) => this.handleChange(e)}
                              id="input-first-name"
                              placeholder={translate("last_name_y")}
                              type="text"
                              readOnly
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              {translate("first_name")}
                            </label>
                            <Input
                              className="form-control-alternative"
                              // defaultValue={
                              //   this.props.userInfo
                              //     ? this.props.userInfo.first_name
                              //     : null
                              // }
                              name="first_name"
                              value={this.state.first_name}
                              onChange={(e) => this.handleChange(e)}
                              id="input-last-name"
                              placeholder={translate("first_name")}
                              type="text"
                              readOnly
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              {translate("first_name_y")}
                            </label>
                            <Input
                              className="form-control-alternative"
                              // defaultValue={
                              //   this.props.userInfo
                              //     ? this.props.userInfo.first_name_yomigana
                              //     : null
                              // }
                              name="first_name_yomigana"
                              value={this.state.first_name_yomigana}
                              onChange={(e) => this.handleChange(e)}
                              id="input-first-name"
                              placeholder={translate("first_name_y")}
                              type="text"
                              readOnly
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              {translate("gender")}
                            </label>
                            <Input
                              className="form-control-alternative"
                              // defaultValue={
                              //   this.props.userInfo
                              //     ? this.props.userInfo.sex
                              //     : null
                              // }
                              name="sex"
                              value={this.state.sex}
                              onChange={(e) => this.handleChange(e)}
                              id="input-last-name"
                              placeholder="Male/Female"
                              type="text"
                              readOnly
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              {translate("dob")}
                            </label>
                            <Input
                              className="form-control-alternative"
                              // defaultValue={
                              //   this.props.userInfo
                              //     ? this.props.userInfo.date_of_birth
                              //     : null
                              // }
                              name="date_of_birth"
                              value={this.state.date_of_birth}
                              onChange={(e) => this.handleChange(e)}
                              id="input-first-name"
                              placeholder={translate("dob")}
                              type="date"
                              readOnly
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              {translate("Mobile_number")}
                            </label>
                            <Input
                              className="form-control-alternative"
                              // defaultValue={
                              //   this.props.userInfo
                              //     ? this.props.userInfo.phone
                              //     : null
                              // }
                              name="phone"
                              value={this.state.phone}
                              onChange={(e) => this.handleChange(e)}
                              id="input-last-name"
                              placeholder={translate("Mobile_number")}
                              type="text"
                              readOnly
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row></Row>
                    </div>
                    <hr className="my-4" />
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return Object.assign({}, state.auth);
};
export default connect(mapStateToProps)(withRouter(withTranslate(Profile)));
