import React from "react";
import {
  Container,
  Card,
  CardHeader,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
import { DataGrid } from "@material-ui/data-grid";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslate } from "react-redux-multilingual";
import { toast } from "react-toastify";
import {
  groupMembersUpdate,
  groupMembersFetch,
  notGroupMembersFetch,
} from "../../actions/groupActions";
import Select from "react-select";
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();
class DoctorsGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      deleteModal: false,
      deleteId: [],
      group_id: "",
      dataset: [],
      doc_id: "",
    };
  }
  componentWillMount() {
    if (this.props.location.state !== undefined) {
      this.setState({
        group_id: this.props.location.state.id,
      });
      this.props.dispatch(
        groupMembersFetch(
          this.props.authInfo,
          this.props.history,
          this.props.location.state.id
        )
      );
      this.props.dispatch(
        notGroupMembersFetch(
          this.props.authInfo,
          this.props.history,
          this.props.location.state.id
        )
      );
    } else {
      this.props.history.push("/admin/user-profile");
    }
  }

  toggle = () => {
    this.setState({ modal: !this.state.modal });
    this.setState({ doc_id: "" });
  };
  toggleDelete = (id) => {
    this.setState({ deleteModal: !this.state.deleteModal });
  };
  handleRemove = (data) => {
    console.log("remove", data);
    this.setState({ deleteId: data });
  };
  deleteAction = () => {
    console.log("selection model", this.state.deleteId);
    if (this.state.deleteId.length > 0) {
      this.props.dispatch(
        groupMembersUpdate(
          this.props.authInfo,
          this.props.history,
          "remove",
          this.state.deleteId,
          this.props.location.state.id
        )
      );
    } else {
      toast.warn("Select a doctor!");
    }
  };
  handleChange(e) {
    this.setState({ doc_id: e.value });
  }

  handleAddSubmit = () => {
    if (this.state.doc_id != "") {
      this.props.dispatch(
        groupMembersUpdate(
          this.props.authInfo,
          this.props.history,
          "add",
          [this.state.doc_id],
          this.props.location.state.id
        )
      );
    } else {
      toast.warn("Select a doctor!");
    }
  };

  render() {
    let options = [];
    if (this.props.notgroupMembers) {
      if (this.props.notgroupMembers.length > 0) {
        for (let i = 0; i < this.props.notgroupMembers.length; i++) {
          options.push({
            value: this.props.notgroupMembers[i].id,
            label:
              this.props.notgroupMembers[i].last_name +
              " " +
              this.props.notgroupMembers[i].first_name,
          });
        }
      }
    }
    const { translate } = this.props;
    const columns = [
      { field: "id", headerName: translate("Doctors_no"), width: 200 },
      {
        field: `last_name`,
        headerName: translate("last_name"),
        width: 120,
      },
      {
        field: `first_name`,
        headerName: translate("first_name"),
        width: 120,
      },
      { field: "email", headerName: translate("Email_IDD"), width: 150 },
      { field: "phone", headerName: translate("Mobile_number"), width: 200 },
      { field: "line_number", headerName: "LINE ID", width: 150 },
      { field: "age", headerName: translate("Age"), width: 120 },
      { field: "state", headerName: translate("Prefecture"), width: 150 },
      {
        field: "city",
        headerName: translate("Prefecture") + " " + translate("City"),
        width: 200,
      },
      {
        field: "clinical_department",
        headerName: translate("Clinical_departmentD"),
        width: 200,
      },
    ];

    return (
      <>
        {/* <Header /> */}
        <div className="pb-6 pt-2 pt-md-8"></div>

        <Container className="mt--6" fluid>
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="4">
                  <h3 className="mb-0">{translate("Doctors_List")}</h3>
                </Col>
                <Col className="text-right" xs="8">
                  <Button
                    color="primary"
                    onClick={() => this.toggle()}
                    size="sm"
                  >
                    {translate("Add_Doc")}
                  </Button>
                  <Button
                    color="danger"
                    onClick={(e) => this.toggleDelete(e)}
                    size="sm"
                    disabled={this.state.deleteId.length == 0}
                  >
                    {translate("Delete")}
                  </Button>
                  <Button
                    color="info"
                    onClick={(e) =>
                      this.props.history.push({
                        pathname: "/admin/user-profile",
                        // page: this.props.location.page,
                      })
                    }
                    size="sm"
                  >
                    {translate("Cancel")}
                  </Button>
                </Col>
              </Row>
            </CardHeader>

            {this.props.groupMembers ? (
              this.props.groupMembers.length > 0 ? (
                <div style={{ height: 400, width: "100%" }}>
                  <DataGrid
                    // {...data}
                    rows={this.props.groupMembers}
                    columns={columns}
                    // isRowSelectable={(params) => params.row.quantity > 50000}
                    checkboxSelection
                    onSelectionModelChange={(itm) => this.handleRemove(itm)}
                  />
                </div>
              ) : (
                <div style={{ textAlign: "center", fontSize: "500" }}>
                  {translate("No_item")}
                </div>
              )
            ) : null}
          </Card>
          <Modal
            isOpen={this.state.modal}
            toggle={() => this.toggle()}
            className={this.className}
          >
            <ModalHeader toggle={() => this.toggle()}>
              {translate("Add_Doctor")}
            </ModalHeader>
            <ModalBody>
              <Form>
                <FormGroup>
                  <Label for="fullName">{translate("Select_Doctors")} </Label>
                  <Select
                    components={animatedComponents}
                    defaultValue={[]}
                    name="doc_id"
                    onChange={(e) => this.handleChange(e)}
                    placeholder="Select Option"
                    // isMulti
                    options={options}
                  />
                  <br />
                </FormGroup>
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={(e) => this.handleAddSubmit(e)}>
                {translate("Add")}
              </Button>{" "}
              <Button color="secondary" onClick={() => this.toggle()}>
                {translate("Cancel")}
              </Button>
            </ModalFooter>
          </Modal>

          <Modal
            isOpen={this.state.deleteModal}
            toggle={() => this.toggleDelete()}
            className={this.className}
          >
            <ModalHeader>{translate("Delete_Window")}</ModalHeader>
            <ModalBody>
              {translate("Are_you_sure_You_want_to_delete_this")}
            </ModalBody>
            <ModalFooter>
              <Button color="danger" onClick={() => this.deleteAction()}>
                {translate("Delete")}
              </Button>{" "}
              <Button color="secondary" onClick={() => this.toggleDelete()}>
                {translate("Cancel")}
              </Button>
            </ModalFooter>
          </Modal>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return Object.assign({}, state.group, state.auth);
};

export default connect(mapStateToProps)(
  withRouter(withTranslate(DoctorsGroup))
);
