export const clinical_department_value = [
  {
    value: "一般内科",
    label: "一般内科",
  },
  {
    value: "消化器内科",
    label: "消化器内科",
  },
  { value: "呼吸器内科", label: "呼吸器内科" },
  { value: "循環器内科", label: "循環器内科" },
  { value: "糖尿病内科", label: "糖尿病内科" },
  { value: "腎臓内科", label: "腎臓内科" },
  { value: "血液内科", label: "血液内科" },
  { value: "神経内科", label: "神経内科" },
  { value: "老年内科", label: "老年内科" },
  { value: "腫瘍内科", label: "腫瘍内科" },
  { value: "心療内科", label: "心療内科" },
  { value: "総合診療科", label: "総合診療科" },
  { value: "その他（内科系）", label: "その他（内科系）" },
  { value: "一般外科", label: "一般外科" },
  { value: "消化器外科", label: "消化器外科" },
  { value: "呼吸器外科", label: "呼吸器外科" },
  { value: "整形外科", label: "整形外科" },
  { value: "脳神経外科", label: "脳神経外科" },
  { value: "形成外科", label: "形成外科" },
  { value: "乳腺外科", label: "乳腺外科" },
  { value: "心臓外科・心臓血管外科", label: "心臓外科・心臓血管外科" },
  { value: "小児外科", label: "小児外科" },
  { value: "美容外科", label: "美容外科" },
  { value: "その他（外科系）", label: "その他（外科系）" },
  { value: "泌尿器科", label: "泌尿器科" },
  { value: "救命救急科", label: "救命救急科" },
  { value: "眼科", label: "眼科" },
  { value: "放射線科", label: "放射線科" },
  { value: "麻酔科", label: "麻酔科" },
  { value: "小児科", label: "小児科" },
  { value: "精神科", label: "精神科" },
  { value: "産科", label: "産科" },
  { value: "婦人科", label: "婦人科" },
  { value: "耳鼻咽喉科", label: "耳鼻咽喉科" },
  { value: "皮膚科", label: "皮膚科" },
  { value: "美容皮膚科", label: "美容皮膚科" },
  { value: "リハビリテーション科", label: "リハビリテーション科" },
  { value: "リウマチ科・膠原病内科", label: "リウマチ科・膠原病内科" },
  {
    value: "病理科・病理診断科・臨床検査科",
    label: "病理科・病理診断科・臨床検査科",
  },
  {
    value: "法医学",
    label: "法医学",
  },
  { value: "専門科問わず 外来", label: "専門科問わず 外来" },
  { value: "病棟管理", label: "病棟管理" },
  { value: "訪問診療", label: "訪問診療" },
  { value: "往診", label: "往診" },
  { value: "オペ", label: "オペ" },
  { value: "コンタクト", label: "コンタクト" },
  { value: "健診（院内）", label: "健診（院内）" },
  { value: "健診（巡回）", label: "健診（巡回）" },
  { value: "透析,内視鏡検査（健診）", label: "透析,内視鏡検査（健診）" },
  { value: "読影（画像診断）", label: "読影（画像診断）" },
  {
    value: "救急対応,オンコール待機（自宅）",
    label: "救急対応,オンコール待機（自宅）",
  },
  {
    value: "オンコール待機（院内・指定場所）",
    label: "オンコール待機（院内・指定場所）",
  },
  { value: "人間ドック", label: "人間ドック" },
  { value: "産業医", label: "産業医" },
  { value: "麻酔管理", label: "麻酔管理" },
  { value: "AGA", label: "AGA" },
  { value: "医療脱毛", label: "医療脱毛" },
  { value: "治験", label: "治験" },
  { value: "開設管理", label: "開設管理" },
  { value: "内視鏡検査（保険診療）", label: "内視鏡検査（保険診療）" },
  {
    value: "カテーテル検査・治療,放射線治療",
    label: "カテーテル検査・治療,放射線治療",
  },
  { value: "予防接種", label: "予防接種" },
  { value: "特殊健診", label: "特殊健診" },
  { value: "乳房視触診", label: "乳房視触診" },
  {
    value: "その他の医師アルバイト・バイト（スポット）",
    label: "その他の医師アルバイト・バイト（スポット）",
  },
];
