import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { validateEmail, validateNull } from "../../services/validation";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { LoginAll } from "../../actions/userActions";
import { withTranslate } from "react-redux-multilingual";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Container
} from "reactstrap";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      email: "",
      password: "",
      authInfo: "",
    };
  }
  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };
  handleSubmit = (event) => {
    const { translate } = this.props;
    event.preventDefault();
    if (validateEmail(this.state.email) && validateNull(this.state.password)) {
      const { email, password } = this.state;
      this.props.dispatch(LoginAll({ email, password }, this.props.history));
    } else {
      if (validateEmail(this.state.email) === false) {
        toast.error(translate("Invalid_email_address"));
      }
      if (validateNull(this.state.password) === false) {
        toast.error(translate("missing_field") + " " + translate("Password"));
      }
    }
  };
  render() {
    const { translate } = this.props;
    return (
      <>
         {/* <div className="pb-10 pt-md-7"></div>
        <Container className="themed-container" fluid="sm"> */}
          <Col lg="5" md="7">
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <small>{translate("Sign_in_with_credentials")}</small>
                </div>
                <Form role="form">
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder={translate("Email_address")}
                        type="email"
                        name="email"
                        value={this.state.email}
                        autoComplete="new-email"
                        onChange={(e) => {
                          this.handleChange(e);
                        }}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder={translate("Password")}
                        type="password"
                        autoComplete="new-password"
                        name="password"
                        value={this.state.password}
                        onChange={(e) => {
                          this.handleChange(e);
                        }}
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <input
                      className="custom-control-input"
                      id=" customCheckLogin"
                      type="checkbox"
                    />
                  </div>
                  <div className="text-center">
                    <Button
                      onClick={(e) => this.handleSubmit(e)}
                      className="my-4"
                      color="primary"
                      type="button"
                    >
                      {translate("Login")}
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
            <Row className="mt-3">
              <Col xs="12">
                <a
                  className="text-light"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <small>{translate("Forgot_password")}?</small>
                </a>
              </Col>
              {/* <Col className="text-right" xs="6">
              <a
                className="text-light"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <small>Create new account</small>
              </a>
            </Col> */}
            </Row>
          </Col>
        {/* </Container> */}
      </>
    );
  }
}

function mapStateToProps(state) {
  return Object.assign({}, state.auth);
}
export default connect(mapStateToProps)(withRouter(withTranslate(Login)));
