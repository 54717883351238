import * as types from "../constants/ActionTypes";
import * as api from "../constants/ReUsageStaff";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { logout } from "./userActions";
toast.configure();

//fetch hospitals
export const fetchDoctorStart = () => ({
  type: types.FETCH_DOCTORS_START,
  payload: {
    doctors: null,
    fetchDoctorLoading: true,
  },
});

export const fetchDoctorSuccess = (data) => ({
  type: types.FETCH_DOCTORS_SUCCESS,
  payload: {
    doctors: data,
    fetchDoctorLoading: false,
  },
});

export const fetchDoctorFailure = (error) => ({
  type: types.FETCH_DOCTORS_FAILURE,
  payload: {
    fetchDoctorLoading: false,
  },
});

export const fetchDoctors = (authInfo, history, page, sort, order_str, srt) => (
  dispatch
) => {
  dispatch(fetchDoctorStart());
  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");
  headers.append("Authorization", "Bearer " + authInfo.access_token);
  headers.append("Origin", "*");
  const requestOptions = {
    method: "GET",
    headers: headers,
  };
  let url = "";
  if (sort === "") {
    url = `${api.get_doctors_url}?page=${page}&order_str=${order_str}`;
  }
  if (sort !== "") {
    url = `${api.get_doctors_url}?page=${page}&user_type=${sort}&sort=${srt}&order_str=${order_str}`;
  }
  fetch(url, requestOptions)
    .then((response) => {
      const statusCode = response.status;
      const data = response.json();
      return Promise.all([statusCode, data]);
    })
    .then(([statusCode, data]) => {
      if (statusCode === 200) {
        dispatch(fetchDoctorSuccess(data));
      } else if (statusCode === 401) {
        toast.error("unauthorized!");
        dispatch(fetchDoctorFailure(statusCode));
        dispatch(logout(history));
      } else if (data.detail) {
        toast.error(data.detail);
        dispatch(fetchDoctorFailure(data.detail));
      } else {
        dispatch(fetchDoctorFailure("Server error!"));
      }
    })
    .catch((error) => {
      toast.error("something went wrong, Try later!");
      dispatch(fetchDoctorFailure(error));
      dispatch(logout(history));
    });
};

//doctor create
export const doctorCreateStart = () => ({
  type: types.CREATE_DOCTORS_START,
  payload: {
    doctorCreateLoading: true,
  },
});

export const doctorCreateSuccess = (data) => ({
  type: types.CREATE_DOCTORS_SUCCESS,
  payload: {
    doctorCreateLoading: true,
  },
});

export const doctorCreateFailure = (error) => ({
  type: types.CREATE_DOCTORS_FAILURE,
  payload: {
    doctorCreateLoading: true,
  },
});

export const doctorCreate = (data, authInfo, history) => (dispatch) => {
  dispatch(doctorCreateStart());
  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");
  headers.append("Authorization", "Bearer " + authInfo.access_token);
  headers.append("Origin", "*");
  const requestOptions = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data),
  };

  fetch(`${api.create_doctor_url}`, requestOptions)
    .then((response) => {
      const statusCode = response.status;
      const data = response.json();
      return Promise.all([statusCode, data]);
    })
    .then(([statusCode, data]) => {
      if (statusCode === 200) {
        toast.success("Doctor Profile Created!");
        dispatch(doctorCreateSuccess(data));
        setTimeout(function () {
          window.location.reload();
        }, 2000);
      } else if (statusCode === 422) {
        toast.error("Unprocessable Entity!");
        dispatch(doctorCreateFailure(statusCode));
      } else if (statusCode === 401) {
        toast.error("unauthorized!");
        dispatch(doctorCreateFailure(statusCode));
        dispatch(logout(history));
      } else if (data.detail) {
        toast.error(data.detail);
        dispatch(doctorCreateFailure(data.detail));
      } else {
        dispatch(doctorCreateFailure("Server error!"));
      }
    })
    .catch((error) => {
      toast.error("something went wrong, Try later!");
      dispatch(doctorCreateFailure(error));
    });
};

//doctor update
export const doctorUpdateStart = () => ({
  type: types.UPDATE_DOCTORS_START,
  payload: {
    doctorUpdateLoading: true,
  },
});

export const doctorUpdateSuccess = (data) => ({
  type: types.UPDATE_DOCTORS_SUCCESS,
  payload: {
    doctorUpdateLoading: true,
  },
});

export const doctorUpdateFailure = (error) => ({
  type: types.UPDATE_DOCTORS_FAILURE,
  payload: {
    doctorUpdateLoading: true,
  },
});

export const doctorUpdate = (data, authInfo, history, hid) => (dispatch) => {
  dispatch(doctorUpdateStart());
  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");
  headers.append("Authorization", "Bearer " + authInfo.access_token);
  headers.append("Origin", "*");
  const requestOptions = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data),
  };

  fetch(`${api.update_doctor_url}${hid}`, requestOptions)
    .then((response) => {
      const statusCode = response.status;
      const data = response.json();
      return Promise.all([statusCode, data]);
    })
    .then(([statusCode, data]) => {
      if (statusCode === 200) {
        toast.success("Profile Updated!");
        dispatch(doctorUpdateSuccess(data));
      } else if (statusCode === 422) {
        toast.error("Unprocessable Entity!");
        dispatch(doctorUpdateFailure(statusCode));
      } else if (statusCode === 401) {
        toast.error("unauthorized!");
        dispatch(doctorUpdateFailure(statusCode));
        dispatch(logout(history));
      } else if (data.detail) {
        toast.error(data.detail);
        dispatch(doctorUpdateFailure(data.detail));
      } else {
        dispatch(doctorUpdateFailure("Server error!"));
      }
    })
    .catch((error) => {
      toast.error("something went wrong, Try later!");
      dispatch(doctorUpdateFailure(error));
    });
};

//doctor info
export const doctorStart = () => ({
  type: types.ME_DOCTOR_START,
  payload: {
    doctorLoading: true,
  },
});

export const doctorSuccess = (data) => ({
  type: types.ME_DOCTOR_SUCCESS,
  payload: {
    doctorInfo: data,
    doctorLoading: true,
  },
});

export const doctorFailure = (error) => ({
  type: types.ME_DOCTOR_FAILURE,
  payload: {
    doctorLoading: true,
  },
});

export const getSingleDoc = (authInfo, history, id) => (dispatch) => {
  dispatch(doctorStart());
  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");
  headers.append("Authorization", "Bearer " + authInfo.access_token);
  headers.append("Origin", "*");
  const requestOptions = {
    method: "GET",
    headers: headers,
  };

  fetch(`${api.doc_get_single}${id}`, requestOptions)
    .then((response) => {
      const statusCode = response.status;
      const data = response.json();
      return Promise.all([statusCode, data]);
    })
    .then(([statusCode, data]) => {
      if (statusCode === 200) {
        dispatch(doctorSuccess(data));
      } else if (statusCode === 401) {
        toast.error("unauthorized!");
        dispatch(doctorFailure(statusCode));
        dispatch(logout(history));
      } else if (data.detail) {
        toast.error(data.detail);
        dispatch(doctorFailure(data.detail));
      } else {
        dispatch(doctorFailure("Server error!"));
      }
    })
    .catch((error) => {
      toast.error("something went wrong, Try later!");
      dispatch(doctorFailure(error));
    });
};
