import React from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
// import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import routes from "./siderbarRoutes";
import CustomProtectedRoute from "../protectors";
import OnlySidebar from "./sidebar"

//side bar
// import Index from "views/Index.js";
import Profile from "views/examples/Profile.js";

import Register from "views/examples/Register.js";

import Doctors from "views/Doctor";
import Hospitals from "views/Hospitals";
import DoctorsGroup from "views/Doctors Group";
import LineNotification from "views/Line Notification";
import Invoice from "views/Invoice";
import InvoicePdf from "views/Invoice/invoice_pdf";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslate } from "react-redux-multilingual";
import HospitalsDetails from "views/Hospitals/details";
import DoctorsList from "views/Doctors Group/docList";
import DoctorsDetails from "views/Doctor/details";
import DoctorsGroupDetails from "views/Doctors Group/details";
import HospitalsDocDetails from "views/Invoice/list";
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
const Admin = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <CustomProtectedRoute
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
const { translate } = props;

  var routes1 = [
  {
    path: "/index",
    name: translate("Dashboard"),
    icon: "ni ni-tv-2 text-primary",
    component: Profile,
    layout: "/admin",
  },
  {
    path: "/Doctor",
    name: translate("Doctor"),
    icon: "ni ni-planet text-blue",
    component: Doctors,
    layout: "/admin",
  },
  {
    path: "/maps",
    name: translate("Hospitals"),
    icon: "ni ni-pin-3 text-orange",
    component: Hospitals,
    layout: "/admin",
  },
  {
    path: "/user-profile",
    name: translate("Doctors_Group"),
    icon: "ni ni-single-02 text-yellow",
    component: DoctorsGroup,
    layout: "/admin",
  },
  {
    path: "/line-notification",
    name: translate("Line_Notification"),
    icon: "ni ni-single-02 text-yellow",
    component: LineNotification,
    layout: "/admin",
  },
  {
    path: "/invoice",
    name: translate("Invoice"),
    icon: "ni ni-single-02 text-yellow",
    component: Invoice,
    layout: "/admin",
  },
];

var routes = [
  {
    path: "/index",
    name:  translate("Dashboard"),
    icon: "ni ni-tv-2 text-primary",
    component: Profile,
    layout: "/admin",
  },
  {
    path: "/Doctor",
    name: translate("Doctor"),
    icon: "ni ni-planet text-blue",
    component: Doctors,
    layout: "/admin",
  },
  // {
  //   path: "/icons",
  //   name: "Doctors",
  //   icon: "ni ni-planet text-blue",
  //   component: Icons,
  //   layout: "/admin",
  // },
  {
    path: "/maps",
    name: translate("Hospitals"),
    icon: "ni ni-pin-3 text-orange",
    component: Hospitals,
    layout: "/admin",
  },
  {
    path: "/hospital-details",
    name:translate("Hospital_Details"),
    icon: "ni ni-key-25 text-info",
    component: HospitalsDetails,
    layout: "/admin",
  },
  {
    path: "/doctor-details",
    name:translate("Doctor_Details"),
    icon: "ni ni-key-25 text-info",
    component: DoctorsDetails,
    layout: "/admin",
  },
  {
    path: "/doctors-group/list",
    name:translate("Doctors_List"),
    icon: "ni ni-key-25 text-info",
    component: DoctorsList,
    layout: "/admin",
  },
  {
    path: "/user-profile",
    name: translate("Doctors_Group"),
    icon: "ni ni-single-02 text-yellow",
    component: DoctorsGroup,
    layout: "/admin",
  },
  {
    path: "/doctor-group-details",
    name: translate("Doctors_Group"),
    icon: "ni ni-single-02 text-yellow",
    component: DoctorsGroupDetails,
    layout: "/admin",
  },
  {
    path: "/line-notification",
    name: translate("Line_Notification"),
    icon: "ni ni-single-02 text-yellow",
    component: LineNotification,
    layout: "/admin",
  },
  {
    path: "/invoice",
    name: translate("Invoice"),
    icon: "ni ni-single-02 text-yellow",
    component: Invoice,
    layout: "/admin",
  },
  {
    path: "/invoice-doc-list",
    name:translate("Hospital_Doctor_List"),
    icon: "ni ni-key-25 text-info",
    component: HospitalsDocDetails,
    layout: "/admin",
  },
];

  return (
    <div style={{overflow: "hidden"}}>
     
       
          <Sidebar
        {...props}
        routes={routes1}
        logo={{
          innerLink: "/admin/index",
          imgSrc: require("../assets/img/brand/argon-react.png").default,
          imgAlt: "...",
        }}
      />
       
    
      <div className="main-content" ref={mainContent} style={{overflow:"auto"}}>
        <AdminNavbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
        />
        <Switch>
          {getRoutes(routes)}
          <Redirect from="*" to="/admin/index" />
        </Switch>
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
export default connect(mapStateToProps, {})(withRouter(withTranslate(Admin)));
