import * as types from "../constants/ActionTypes";
import * as api from "../constants/ReUsageStaff";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { logout } from "./userActions";
toast.configure();

//fetch hospitals
export const fetchHospitalStart = () => ({
  type: types.FETCH_HOSPITALS_START,
  payload: {
    hospitals: null,
    fetchHospitalLoading: true,
  },
});

export const fetchHospitalSuccess = (data) => ({
  type: types.FETCH_HOSPITALS_SUCCESS,
  payload: {
    hospitals: data,
    fetchHospitalLoading: false,
  },
});

export const fetchHospitalFailure = (error) => ({
  type: types.FETCH_HOSPITALS_FAILURE,
  payload: {
    fetchHospitalLoading: false,
  },
});

export const fetchHospitals = (authInfo, history, page, sort, order_str) => (
  dispatch
) => {
  dispatch(fetchHospitalStart());
  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");
  headers.append("Authorization", "Bearer " + authInfo.access_token);
  headers.append("Origin", "*");
  const requestOptions = {
    method: "GET",
    headers: headers,
  };
  let url = "";
  if (sort === "") {
    url = `${api.get_hospitals_url}?page=${page}&order_str=${order_str}`;
  }
  if (sort !== "") {
    url = `${api.get_hospitals_url}?page=${page}&sort=${sort}&order_str=${order_str}`;
  }
  fetch(url, requestOptions)
    .then((response) => {
      const statusCode = response.status;
      const data = response.json();
      return Promise.all([statusCode, data]);
    })
    .then(([statusCode, data]) => {
      if (statusCode === 200) {
        dispatch(fetchHospitalSuccess(data));
      } else if (statusCode === 401) {
        toast.error("unauthorized!");
        dispatch(fetchHospitalFailure(statusCode));
        dispatch(logout(history));
      } else if (data.detail) {
        toast.error(data.detail);
        dispatch(fetchHospitalFailure(data.detail));
      } else {
        dispatch(fetchHospitalFailure("Server error!"));
      }
    })
    .catch((error) => {
      toast.error("something went wrong, Try later!");
      dispatch(fetchHospitalFailure(error));
    });
};

//hospital info
export const hospitalStart = () => ({
  type: types.US_HOSPITALS_START,
  payload: {
    hospitalLoading: true,
  },
});

export const hospitalSuccess = (data) => ({
  type: types.US_HOSPITALS_SUCCESS,
  payload: {
    hospitalInfo: data,
    hospitalLoading: true,
  },
});

export const hospitalFailure = (error) => ({
  type: types.US_HOSPITALS_FAILURE,
  payload: {
    hospitalLoading: true,
  },
});

export const getSingleHospital = (authInfo, history) => (dispatch) => {
  dispatch(hospitalStart());
  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");
  headers.append("Authorization", "Bearer " + authInfo.access_token);
  headers.append("Origin", "*");
  const requestOptions = {
    method: "GET",
    headers: headers,
  };

  fetch(`${api.details_hospital_url}`, requestOptions)
    .then((response) => {
      const statusCode = response.status;
      const data = response.json();
      return Promise.all([statusCode, data]);
    })
    .then(([statusCode, data]) => {
      if (statusCode === 200) {
        dispatch(hospitalSuccess(data));
      } else if (statusCode === 401) {
        toast.error("unauthorized!");
        dispatch(hospitalFailure(statusCode));
        dispatch(logout(history));
      } else if (data.detail) {
        toast.error(data.detail);
        dispatch(hospitalFailure(data.detail));
      } else {
        dispatch(hospitalFailure("Server error!"));
      }
    })
    .catch((error) => {
      toast.error("something went wrong, Try later!");
      dispatch(hospitalFailure(error));
    });
};

//hospital create
export const hospitalCreateStart = () => ({
  type: types.CREATE_HOSPITALS_START,
  payload: {
    hospitalCreateLoading: true,
  },
});

export const hospitalCreateSuccess = (data) => ({
  type: types.CREATE_HOSPITALS_SUCCESS,
  payload: {
    hospitalCreateLoading: true,
  },
});

export const hospitalCreateFailure = (error) => ({
  type: types.CREATE_HOSPITALS_FAILURE,
  payload: {
    hospitalCreateLoading: true,
  },
});

export const hospitalCreate = (data, authInfo, history) => (dispatch) => {
  dispatch(hospitalCreateStart());
  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");
  headers.append("Authorization", "Bearer " + authInfo.access_token);
  headers.append("Origin", "*");
  const requestOptions = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data),
  };

  fetch(`${api.create_hospital_url}`, requestOptions)
    .then((response) => {
      const statusCode = response.status;
      const data = response.json();
      return Promise.all([statusCode, data]);
    })
    .then(([statusCode, data]) => {
      if (statusCode === 200) {
        toast.success("Hospital Created!");
        dispatch(hospitalCreateSuccess(data));
        setTimeout(function () {
          window.location.reload();
        }, 2000);
      } else if (statusCode === 422) {
        toast.error("Unprocessable Entity!");
        dispatch(hospitalCreateFailure(statusCode));
      } else if (statusCode === 401) {
        toast.error("unauthorized!");
        dispatch(hospitalCreateFailure(statusCode));
        dispatch(logout(history));
      } else if (data.detail) {
        toast.error(data.detail);
        dispatch(hospitalCreateFailure(data.detail));
      } else {
        dispatch(hospitalCreateFailure("Server error!"));
      }
    })
    .catch((error) => {
      toast.error("something went wrong, Try later!");
      dispatch(hospitalCreateFailure(error));
    });
};

//hospital update
export const hospitalUpdateStart = () => ({
  type: types.UPDATE_HOSPITALS_START,
  payload: {
    hospitalUpdateLoading: true,
  },
});

export const hospitalUpdateSuccess = (data) => ({
  type: types.UPDATE_HOSPITALS_SUCCESS,
  payload: {
    hospitalUpdateLoading: true,
  },
});

export const hospitalUpdateFailure = (error) => ({
  type: types.UPDATE_HOSPITALS_FAILURE,
  payload: {
    hospitalUpdateLoading: true,
  },
});

export const hospitalUpdate = (data, authInfo, history, hid) => (dispatch) => {
  dispatch(hospitalUpdateStart());
  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");
  headers.append("Authorization", "Bearer " + authInfo.access_token);
  headers.append("Origin", "*");
  const requestOptions = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data),
  };

  fetch(`${api.update_hospital_url}${hid}`, requestOptions)
    .then((response) => {
      const statusCode = response.status;
      const data = response.json();
      return Promise.all([statusCode, data]);
    })
    .then(([statusCode, data]) => {
      if (statusCode === 200) {
        toast.success("Hospital Updated!");
        dispatch(hospitalUpdateSuccess(data));
      } else if (statusCode === 422) {
        toast.error("Unprocessable Entity!");
        dispatch(hospitalUpdateFailure(statusCode));
      } else if (statusCode === 401) {
        toast.error("unauthorized!");
        dispatch(hospitalUpdateFailure(statusCode));
        dispatch(logout(history));
      } else if (data.detail) {
        toast.error(data.detail);
        dispatch(hospitalUpdateFailure(data.detail));
      } else {
        dispatch(hospitalUpdateFailure("Server error!"));
      }
    })
    .catch((error) => {
      toast.error("something went wrong, Try later!");
      dispatch(hospitalUpdateFailure(error));
      dispatch(logout(history));
    });
};

//get single hospital info


export const fetchSingleHospitalStart = () => ({
  type: types.FETCH_SINGLE_HOSPITAL_START,
  payload: {
    fetchSingleHospitalLoading: true,
  },
});

export const fetchSingleHospitalSuccess = (data) => ({
  type: types.FETCH_SINGLE_HOSPITAL_SUCCESS,
  payload: {
    fetchSingleHospitalInfo: data,
    fetchSingleHospitalLoading: false,
  },
});

export const fetchSingleHospitalFailure = (error) => ({
  type: types.FETCH_SINGLE_HOSPITAL_FAILURE,
  payload: {
    fetchSingleHospitalLoading: false,
  },
});

export const fetchSingleHospital = (authInfo, history,id) => (dispatch) => {
  dispatch(fetchSingleHospitalStart());

  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");
  headers.append("Authorization", "Bearer " + authInfo.access_token);
  headers.append("Origin", "*");
  const requestOptions = {
    method: "GET",
    headers: headers,
  };

  fetch(`${api.get_single_hospital}${id}`, requestOptions)
    .then((response) => {
      const statusCode = response.status;
      const data = response.json();
      return Promise.all([statusCode, data]);

    })
    .then(([statusCode, data]) => {
      if (statusCode === 200) {

        dispatch(fetchSingleHospitalSuccess(data));
      } else if (statusCode === 401) {
        toast.error("unauthorized!");
        dispatch(fetchSingleHospitalFailure(statusCode));
        dispatch(logout(history));
      } else if (data.detail) {
        toast.error(data.detail);
        dispatch(fetchSingleHospitalFailure(data.detail));
      } else {
        dispatch(fetchSingleHospitalFailure("Server error!"));
      }
    })
    .catch((error) => {
      toast.error("something went wrong, Try later!");
      dispatch(fetchSingleHospitalFailure(error));
    });
};
