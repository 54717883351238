import * as types from "../constants/ActionTypes";
import * as api from "../constants/ReUsageStaff";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { logout } from "./userActions";
toast.configure();

//fetch groups
export const fetchGroupStart = () => ({
  type: types.FETCH_GROUPS_START,
  payload: {
    groups: null,
    fetchGroupLoading: true,
  },
});

export const fetchGroupSuccess = (data) => ({
  type: types.FETCH_GROUPS_SUCCESS,
  payload: {
    groups: data,
    fetchGroupLoading: false,
  },
});

export const fetchGroupFailure = (error) => ({
  type: types.FETCH_GROUPS_FAILURE,
  payload: {
    fetchGroupLoading: false,
  },
});

export const fetchGroups = (authInfo, history, page, sort, order_str) => (
  dispatch
) => {
  dispatch(fetchGroupStart());
  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");
  headers.append("Authorization", "Bearer " + authInfo.access_token);
  headers.append("Origin", "*");
  const requestOptions = {
    method: "GET",
    headers: headers,
  };
  let url = "";
  if (sort === "") {
    url = `${api.get_groups_url}?page=${page}&order_str=${order_str}`;
  }
  if (sort !== "") {
    url = `${api.get_groups_url}?page=${page}&sort=${sort}&order_str=${order_str}`;
  }
  fetch(url, requestOptions)
    .then((response) => {
      const statusCode = response.status;
      const data = response.json();
      return Promise.all([statusCode, data]);
    })
    .then(([statusCode, data]) => {
      if (statusCode === 200) {
        dispatch(fetchGroupSuccess(data));
      } else if (statusCode === 401) {
        toast.error("unauthorized!");
        dispatch(fetchGroupFailure(statusCode));
        dispatch(logout(history));
      } else if (data.detail) {
        toast.error(data.detail);
        dispatch(fetchGroupFailure(data.detail));
      } else {
        dispatch(fetchGroupFailure("Server error!"));
      }
    })
    .catch((error) => {
      toast.error("something went wrong, Try later!");
      dispatch(fetchGroupFailure(error));
      dispatch(logout(history));
    });
};

//delete group
export const deletegroupStart = () => ({
  type: types.DELETE_GROUPS_START,
  payload: {
    groupLoading: true,
  },
});

export const deletegroupSuccess = (data) => ({
  type: types.DELETE_GROUPS_SUCCESS,
  payload: {
    groupInfo: data,
    groupLoading: false,
  },
});

export const deletegroupFailure = (error) => ({
  type: types.DELETE_GROUPS_FAILURE,
  payload: {
    groupLoading: false,
  },
});

export const deletegroup = (authInfo, history, id, type) => (dispatch) => {
  dispatch(deletegroupStart());
  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");
  headers.append("Authorization", "Bearer " + authInfo.access_token);
  headers.append("Origin", "*");
  const requestOptions = {
    method: "GET",
    headers: headers,
  };
  let hit_url = "";
  if (type === "hos") {
    hit_url = api.delete_group_url;
  }
  if (type === "doc") {
    hit_url = api.delete_group_url;
  }
  if (type === "grp") {
    hit_url = api.delete_group_url;
  }
  fetch(`${hit_url}${id}`, requestOptions)
    .then((response) => {
      const statusCode = response.status;
      const data = response.json();
      return Promise.all([statusCode, data]);
    })
    .then(([statusCode, data]) => {
      if (statusCode === 200) {
        dispatch(deletegroupSuccess(data));
        toast.error(" deleted !");
        setTimeout(function () {
          window.location.reload();
        }, 2000);
      } else if (statusCode === 401) {
        toast.error("unauthorized!");
        dispatch(deletegroupFailure(statusCode));
        dispatch(logout(history));
      } else if (data.detail) {
        toast.error(data.detail);
        dispatch(deletegroupFailure(data.detail));
      } else {
        dispatch(deletegroupFailure("Server error!"));
      }
    })
    .catch((error) => {
      toast.error("something went wrong, Try later!");
      dispatch(deletegroupFailure(error));
    });
};

//group create
export const groupCreateStart = () => ({
  type: types.CREATE_GROUPS_START,
  payload: {
    groupCreateLoading: true,
  },
});

export const groupCreateSuccess = (data) => ({
  type: types.CREATE_GROUPS_SUCCESS,
  payload: {
    groupCreateLoading: false,
  },
});

export const groupCreateFailure = (error) => ({
  type: types.CREATE_GROUPS_FAILURE,
  payload: {
    groupCreateLoading: false,
  },
});

export const groupCreate = (data, authInfo, history) => (dispatch) => {
  dispatch(groupCreateStart());
  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");
  headers.append("Authorization", "Bearer " + authInfo.access_token);
  headers.append("Origin", "*");
  const requestOptions = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data),
  };

  fetch(`${api.create_group_url}`, requestOptions)
    .then((response) => {
      const statusCode = response.status;
      const data = response.json();
      return Promise.all([statusCode, data]);
    })
    .then(([statusCode, data]) => {
      if (statusCode === 200) {
        toast.success("Group Created!");
        dispatch(groupCreateSuccess(data));
        setTimeout(function () {
          window.location.reload();
        }, 2000);
      } else if (statusCode === 422) {
        toast.error("Unprocessable Entity!");
        dispatch(groupCreateFailure(statusCode));
      } else if (statusCode === 401) {
        toast.error("unauthorized!");
        dispatch(groupCreateFailure(statusCode));
        dispatch(logout(history));
      } else if (data.detail) {
        toast.error(data.detail);
        dispatch(groupCreateFailure(data.detail));
      } else {
        dispatch(groupCreateFailure("Server error!"));
      }
    })
    .catch((error) => {
      toast.error("something went wrong, Try later!");
      dispatch(groupCreateFailure(error));
    });
};

//group update
export const groupUpdateStart = () => ({
  type: types.UPDATE_GROUPS_START,
  payload: {
    groupUpdateLoading: true,
  },
});

export const groupUpdateSuccess = (data) => ({
  type: types.UPDATE_GROUPS_SUCCESS,
  payload: {
    groupUpdateLoading: false,
  },
});

export const groupUpdateFailure = (error) => ({
  type: types.UPDATE_GROUPS_FAILURE,
  payload: {
    groupUpdateLoading: false,
  },
});

export const groupUpdate = (data, authInfo, history, hid) => (dispatch) => {
  dispatch(groupUpdateStart());
  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");
  headers.append("Authorization", "Bearer " + authInfo.access_token);
  headers.append("Origin", "*");
  const requestOptions = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data),
  };

  fetch(`${api.update_group_url}${hid}`, requestOptions)
    .then((response) => {
      const statusCode = response.status;
      const data = response.json();
      return Promise.all([statusCode, data]);
    })
    .then(([statusCode, data]) => {
      if (statusCode === 200) {
        toast.success("Group Updated!");
        dispatch(groupUpdateSuccess(data));
      } else if (statusCode === 422) {
        toast.error("Unprocessable Entity!");
        dispatch(groupUpdateFailure(statusCode));
      } else if (statusCode === 401) {
        toast.error("unauthorized!");
        dispatch(groupUpdateFailure(statusCode));
        dispatch(logout(history));
      } else if (data.detail) {
        toast.error(data.detail);
        dispatch(groupUpdateFailure(data.detail));
      } else {
        dispatch(groupUpdateFailure("Server error!"));
      }
    })
    .catch((error) => {
      toast.error("something went wrong, Try later!");
      dispatch(groupUpdateFailure(error));
    });
};

//group members update
export const groupMembersUpdateStart = () => ({
  type: types.UPDATE_GROUPS_MEMBERS_START,
  payload: {
    groupMembersUpdateLoading: true,
  },
});

export const groupMembersUpdateSuccess = (data) => ({
  type: types.UPDATE_GROUPS_MEMBERS_SUCCESS,
  payload: {
    groupMembersUpdateLoading: false,
  },
});

export const groupMembersUpdateFailure = (error) => ({
  type: types.UPDATE_GROUPS_MEMBERS_FAILURE,
  payload: {
    groupMembersUpdateLoading: false,
  },
});

export const groupMembersUpdate = (authInfo, history, type, id, gid) => (
  dispatch
) => {
  dispatch(groupMembersUpdateStart());
  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");
  headers.append("Authorization", "Bearer " + authInfo.access_token);
  headers.append("Origin", "*");
  const requestOptions = {
    method: "PATCH",
    headers: headers,
  };
  // console.log("id", hid);
  // let id = [hid];
  let members = `&members=${id}`;
  if (id.length > 1) {
    members = "";
    for (let i = 0; i < id.length; i++) {
      members = members + `&members=${id[i]}`;
    }
  }
  fetch(
    `${api.update_group_members_url}?group_id=${gid}&action_type=${type}${members}`,
    requestOptions
  )
    .then((response) => {
      const statusCode = response.status;
      const data = response.json();
      return Promise.all([statusCode, data]);
    })
    .then(([statusCode, data]) => {
      if (statusCode === 200) {
        toast.success("Updated!");
        dispatch(groupMembersUpdateSuccess(data));
        // if (type === "add") {
        setTimeout(function () {
          window.location.reload();
        }, 2000);
        // }
      } else if (statusCode === 422) {
        toast.error("Unprocessable Entity!");
        dispatch(groupMembersUpdateFailure(statusCode));
      } else if (statusCode === 401) {
        toast.error("unauthorized!");
        dispatch(groupMembersUpdateFailure(statusCode));
        dispatch(logout(history));
      } else if (data.detail) {
        toast.error(data.detail);
        dispatch(groupMembersUpdateFailure(data.detail));
      } else {
        dispatch(groupMembersUpdateFailure("Server error!"));
      }
    })
    .catch((error) => {
      toast.error("something went wrong, Try later!");
      dispatch(groupMembersUpdateFailure(error));
    });
};

//fetch group members
export const groupMembersfetchStart = () => ({
  type: types.FETCH_GROUPS_MEMBERS_START,
  payload: {
    groupMembersfetchLoading: true,
  },
});

export const groupMembersfetchSuccess = (data) => ({
  type: types.FETCH_GROUPS_MEMBERS_SUCCESS,
  payload: {
    groupMembers: data,
    groupMembersfetchLoading: false,
  },
});

export const groupMembersfetchFailure = (error) => ({
  type: types.FETCH_GROUPS_MEMBERS_FAILURE,
  payload: {
    groupMembersfetchLoading: false,
  },
});

export const groupMembersFetch = (authInfo, history, hid) => (dispatch) => {
  dispatch(groupMembersfetchStart());
  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");
  headers.append("Authorization", "Bearer " + authInfo.access_token);
  headers.append("Origin", "*");
  const requestOptions = {
    method: "GET",
    headers: headers,
  };

  fetch(`${api.fetch_group_members_url}${hid}`, requestOptions)
    .then((response) => {
      const statusCode = response.status;
      const data = response.json();
      return Promise.all([statusCode, data]);
    })
    .then(([statusCode, data]) => {
      if (statusCode === 200) {
        dispatch(groupMembersfetchSuccess(data));
      } else if (statusCode === 422) {
        toast.error("Unprocessable Entity!");
        dispatch(groupMembersfetchFailure(statusCode));
      } else if (statusCode === 401) {
        toast.error("unauthorized!");
        dispatch(groupMembersfetchFailure(statusCode));
        dispatch(logout(history));
      } else if (data.detail) {
        toast.error(data.detail);
        dispatch(groupMembersfetchFailure(data.detail));
      } else {
        dispatch(groupMembersfetchFailure("Server error!"));
      }
    })
    .catch((error) => {
      toast.error("something went wrong, Try later!");
      dispatch(groupMembersfetchFailure(error));
    });
};

//fetch members not in a group
export const notgroupMembersfetchStart = () => ({
  type: types.FETCH_NOT_GROUPS_MEMBERS_START,
  payload: {
    notgroupMembersfetchLoading: true,
  },
});

export const notgroupMembersfetchSuccess = (data) => ({
  type: types.FETCH_NOT_GROUPS_MEMBERS_SUCCESS,
  payload: {
    notgroupMembers: data,
    notgroupMembersfetchLoading: true,
  },
});

export const notgroupMembersfetchFailure = (error) => ({
  type: types.FETCH_NOT_GROUPS_MEMBERS_FAILURE,
  payload: {
    notgroupMembersfetchLoading: true,
  },
});

export const notGroupMembersFetch = (authInfo, history, hid) => (dispatch) => {
  dispatch(notgroupMembersfetchStart());
  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");
  headers.append("Authorization", "Bearer " + authInfo.access_token);
  headers.append("Origin", "*");
  const requestOptions = {
    method: "GET",
    headers: headers,
  };

  fetch(`${api.fetch_members_not_in_group__url}${hid}`, requestOptions)
    .then((response) => {
      const statusCode = response.status;
      const data = response.json();
      return Promise.all([statusCode, data]);
    })
    .then(([statusCode, data]) => {
      if (statusCode === 200) {
        dispatch(notgroupMembersfetchSuccess(data));
      } else if (statusCode === 422) {
        toast.error("Unprocessable Entity!");
        dispatch(notgroupMembersfetchFailure(statusCode));
      } else if (statusCode === 401) {
        toast.error("unauthorized!");
        dispatch(notgroupMembersfetchFailure(statusCode));
        dispatch(logout(history));
      } else if (data.detail) {
        toast.error(data.detail);
        dispatch(notgroupMembersfetchFailure(data.detail));
      } else {
        dispatch(notgroupMembersfetchFailure("Server error!"));
      }
    })
    .catch((error) => {
      toast.error("something went wrong, Try later!");
      dispatch(notgroupMembersfetchFailure(error));
    });
};
