import React from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslate } from "react-redux-multilingual";
import { validateNull } from "../../services/validation";
import { groupUpdate } from "../../actions/groupActions";
import { toast } from "react-toastify";

class HospitalsDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentWillMount() {
    if (this.props.location.state !== undefined) {
      this.setState({
        group_name: this.props.location.state.group_name,
        description: this.props.location.state.description,
      });
    } else {
      this.props.history.push("/admin/user-profile");
    }
  }
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }
  handleSubmit = (e) => {
    const { translate } = this.props;
    e.preventDefault();
    let data = {
      group_name: this.state.group_name,
      description: this.state.description,
    };
    if (
      validateNull(this.state.group_name) &&
      validateNull(this.state.description)
    ) {
      this.props.dispatch(
        groupUpdate(
          data,
          this.props.authInfo,
          this.props.history,
          this.props.location.state.id
        )
      );
    } else {
      if (validateNull(this.state.group_name) === false) {
        toast.error(translate("missing_field") + " Group Name");
      }
      if (validateNull(this.state.description) === false) {
        toast.error(translate("missing_field") + " Explanation of group");
      }
    }
  };
  render() {
    const { translate } = this.props;
    return (
      <>
        <div className="pb-6 pt-2 pt-md-8"></div>

        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Doctor Group</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button
                        color="primary"
                        href="#pablo"
                        onClick={(e) => this.handleSubmit(e)}
                        size="sm"
                      >
                        {translate("Update")}
                      </Button>
                      <Button
                        color="info"
                        onClick={(e) =>
                          this.props.history.push("/admin/user-profile")
                        }
                        size="sm"
                      >
                        {translate("Cancel")}
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              {translate("Group_Name")}
                            </label>
                            <Input
                              className="form-control-alternative"
                              type="text"
                              name="group_name"
                              value={this.state.group_name}
                              onChange={(e) => this.handleChange(e)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              {translate("Explanation_of_group")}
                            </label>
                            <Input
                              className="form-control-alternative"
                              type="text"
                              value={this.state.description}
                              name="description"
                              onChange={(e) => this.handleChange(e)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

// export default Profile;
const mapStateToProps = (state) => {
  return Object.assign({}, state.hospital, state.auth);
};

export default connect(mapStateToProps)(
  withRouter(withTranslate(HospitalsDetails))
);
