import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslate } from "react-redux-multilingual";
import {
  validateEmail,
  validateNull,
  validatePassword,
} from "../../services/validation";
import { doctorUpdate, getSingleDoc } from "../../actions/doctorActions";
import { toast } from "react-toastify";
import { clinical_department_value } from "../../constants/clinicalDepartment";
import Select from "react-select";
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();
class HospitalsDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dept: [],
      docValue: null,
      selectDefaultValue: [],
      clinicalselected: [],
    };
  }
  componentWillMount() {
    if (this.props.location.state !== undefined) {
      this.props.dispatch(
        getSingleDoc(
          this.props.authInfo,
          this.props.history,
          this.props.location.state.id
        )
      );

      const doctorinfo = this.props.location?.state;
      let uniqueclinical_department = [];

      if (
        doctorinfo.clinical_department &&
        doctorinfo.clinical_department.length > 0
      ) {
        uniqueclinical_department = new Set(
          doctorinfo.clinical_department.split(",")
        );
        uniqueclinical_department = [...uniqueclinical_department];
      }
      this.setState({
        first_name: this.props.location.state.first_name,
        first_name_yomigana: this.props.location.state.first_name_yomigana,
        last_name: this.props.location.state.last_name,
        last_name_yomigana: this.props.location.state.last_name_yomigana,
        age: this.props.location.state.age,
        university: this.props.location.state.university,
        image: this.props.location.state.image,
        date_of_birth: this.props.location.state.date_of_birth,
        sex: this.props.location.state.sex,
        email: this.props.location.state.email,
        country: this.props.location.state.country,
        state: this.props.location.state.state,
        city: this.props.location.state.city,
        address_line1: this.props.location.state.address_line1,
        address_line2: this.props.location.state.address_line2,
        building_name: this.props.location.state.building_name,
        zip_code: this.props.location.state.zip_code,

        license_approval_date: this.props.location.state.license_approval_date,
        phone: this.props.location.state.phone,
        line_number: this.props.location.state.line_number,
        password: "",
        clinical_department: this.props.location.state.clinical_department,
        clinicalselected: uniqueclinical_department,
      });
      // if (this.props.location.state.clinical_department != null) {
      //   this.setState({
      //     clinical_department: this.props.location.state.clinical_department.split(
      //       ","
      //     ),
      //   });
      // }
      if (this.props.location.state.graduation_date != null) {
        this.setState({
          graduation_date_value:
            this.props.location.state.graduation_date.split("-")[0] +
            "-" +
            this.props.location.state.graduation_date.split("-")[1],
        });
      }
    } else {
      this.props.history.push("/admin/Doctor");
    }
  }
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });

    if (name === "date_of_birth") {
      const parts = value.match(/(\d+)/g);
      const dob = new Date(parts[0], parts[1] - 1, parts[2]); // months are 0-based
      var ageDifMs = Date.now() - dob.getTime();
      var ageDate = new Date(ageDifMs); // miliseconds from epoch
      this.setState({ age: Math.abs(ageDate.getUTCFullYear() - 1970) });
    }
  }
  handleSelectChange = (e) => {
    this.setState({
      dept: Array.isArray(e) ? e.map((x) => x.value) : [],
    });
  };
  componentWillReceiveProps(newprops) {
    if (this.state.docValue != newprops.doctorInfo) {
      let uniqueclinical_department = [];

      if (
        newprops.doctorInfo.clinical_department &&
        newprops.doctorInfo.clinical_department.length > 0
      ) {
        uniqueclinical_department = new Set(
          newprops.doctorInfo.clinical_department.split(",")
        );
        uniqueclinical_department = [...uniqueclinical_department];
      }
      this.setState({
        first_name: newprops.doctorInfo.first_name,
        first_name_yomigana: newprops.doctorInfo.first_name_yomigana,
        last_name: newprops.doctorInfo.last_name,
        last_name_yomigana: newprops.doctorInfo.last_name_yomigana,
        age: newprops.doctorInfo.age,
        university: newprops.doctorInfo.university,
        image: newprops.doctorInfo.image,
        date_of_birth: newprops.doctorInfo.date_of_birth,
        sex: newprops.doctorInfo.sex,
        email: newprops.doctorInfo.email,
        country: newprops.doctorInfo.country,
        state: newprops.doctorInfo.state,
        city: newprops.doctorInfo.city,
        address_line1: newprops.doctorInfo.address_line1,
        address_line2: newprops.doctorInfo.address_line2,
        building_name: newprops.doctorInfo.building_name,
        zip_code: newprops.doctorInfo.zip_code,

        license_approval_date: newprops.doctorInfo.license_approval_date,
        phone: newprops.doctorInfo.phone,
        line_number: newprops.doctorInfo.line_number,
        password: "",
        clinicalselected: uniqueclinical_department,
        clinical_department: newprops.doctorInfo.clinical_department,
      });
    }
    if (newprops.doctorInfo != undefined) {
      if (newprops.doctorInfo.graduation_date != null) {
        this.setState({
          graduation_date_value:
            newprops.doctorInfo.graduation_date.split("-")[0] +
            "-" +
            newprops.doctorInfo.graduation_date.split("-")[1],
        });
      }
    }
  }

  removeEmpty = (state) => {
    Object.keys(state).forEach(
      (k) => !state[k] && state[k] !== undefined && delete state[k]
    );

    return state;
  };

  handleSubmit = async (e) => {
    const { translate } = this.props;
    e.preventDefault();
    let dept = this.state.clinical_department;
    if (dept === null) {
      dept = "";
    } else if (typeof this.state.clinical_department == "object") {
      dept = this.state.clinical_department.join();
    }

    let data = {
      first_name: this.state.first_name,
      first_name_yomigana: this.state.first_name_yomigana,
      last_name: this.state.last_name,
      last_name_yomigana: this.state.last_name_yomigana,
      age: this.state.age,
      university: this.state.university,
      clinical_department: dept,
      // clinical_department:
      //   this.state.dept.length == 0
      //     ? this.state.clinical_department.join()
      //     : this.state.dept.join(),
      date_of_birth: this.state.date_of_birth,
      sex: this.state.sex,
      email: this.state.email,
      country: this.state.country,
      state: this.state.state,
      city: this.state.city,
      address_line1: this.state.address_line1,
      address_line2: this.state.address_line2,
      building_name: this.state.building_name,
      zip_code: this.state.zip_code,
      graduation_date: this.state.graduation_date,
      license_approval_date: this.state.license_approval_date,
      phone: this.state.phone,
      line_number: this.state.line_number,
      password: this.state.password,
    };
    let hd = await this.removeEmpty(data);
    if (
      validateEmail(this.state.email) &&
      ((this.state.password.length > 0 &&
        validatePassword(this.state.password)) ||
        this.state.password.length === 0) &&
      validateNull(this.state.last_name) &&
      validateNull(this.state.first_name) &&
      validateNull(this.state.phone)
      // validateNull(this.state.line_number)
    ) {
      this.props.dispatch(
        doctorUpdate(
          hd,
          this.props.authInfo,
          this.props.history,
          this.props.location.state.id
        )
      );
      this.setState({ clinical_department: [] });
      setTimeout(() => {
        this.props.dispatch(
          getSingleDoc(
            this.props.authInfo,
            this.props.history,
            this.props.location.state.id
          )
        );
      }, 2000);
    } else {
      if (validateEmail(this.state.email) === false) {
        toast.error(translate("Invalid_email_address"));
      }
      if (this.state.password.length > 0) {
        if (validatePassword(this.state.password) === false) {
          toast.error("Password is not valid");
        }
      }
      if (validateNull(this.state.last_name) === false) {
        toast.error(translate("missing_field") + " Last Name");
      }
      if (validateNull(this.state.first_name) === false) {
        toast.error(translate("missing_field") + " First Name");
      }
      if (validateNull(this.state.phone) === false) {
        toast.error(translate("missing_field") + " Mobile Number");
      }
      if (validateNull(this.state.line_number) === false) {
        toast.error(translate("missing_field") + " Line ID");
      }
    }
  };

  demo(arr1, arr2) {
    const value = [];
    for (const val of arr2) {
      if (arr1.includes(val.label)) {
        value.push(val);
      }
    }

    return value;
  }
  render() {
    const { translate } = this.props;

    return (
      <>
        <div className="pb-6 pt-2 pt-md-8"></div>

        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">{translate("Doctor_profile")}</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button
                        color="primary"
                        href="#pablo"
                        onClick={(e) => this.handleSubmit(e)}
                        size="sm"
                      >
                        {translate("Update")}
                      </Button>
                      <Button
                        color="info"
                        onClick={(e) =>
                          this.props.history.push({
                            pathname: "/admin/Doctor",
                            page: this.props.location.page,
                          })
                        }
                        size="sm"
                      >
                        {translate("Cancel")}
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          {this.state.image ? (
                            <img
                              alt="doc_img"
                              className="form-control"
                              src={this.state.image}
                              style={{ height: "auto" }}
                            />
                          ) : null}
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              {translate("Email_IDD")}
                            </label>
                            <Input
                              className="form-control-alternative"
                              type="email"
                              name="email"
                              value={this.state.email}
                              onChange={(e) => this.handleChange(e)}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              {translate("Password")}
                            </label>
                            <Input
                              className="form-control-alternative"
                              name="password"
                              value={this.state.password}
                              onChange={(e) => {
                                this.handleChange(e);
                              }}
                              type="password"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              {translate("Last_name_doctor")}
                            </label>
                            <Input
                              className="form-control-alternative"
                              type="text"
                              value={this.state.last_name}
                              name="last_name"
                              onChange={(e) => this.handleChange(e)}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              {translate("Last_name_katakana")}
                            </label>
                            <Input
                              className="form-control-alternative"
                              type="text"
                              name="last_name_yomigana"
                              value={this.state.last_name_yomigana}
                              onChange={(e) => this.handleChange(e)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              {translate("First_name_doctor")}
                            </label>
                            <Input
                              className="form-control-alternative"
                              type="text"
                              name="first_name"
                              value={this.state.first_name}
                              onChange={(e) => this.handleChange(e)}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              {translate("First_name_katakana")}
                            </label>
                            <Input
                              className="form-control-alternative"
                              type="text"
                              name="first_name_yomigana"
                              value={this.state.first_name_yomigana}
                              onChange={(e) => {
                                this.handleChange(e);
                              }}
                            ></Input>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              {translate("dob")}
                            </label>
                            <Input
                              className="form-control-alternative"
                              type="date"
                              name="date_of_birth"
                              max="9999-12-31"
                              value={this.state.date_of_birth}
                              onChange={(e) => {
                                this.handleChange(e);
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              {translate("Age")}
                            </label>
                            <Input
                              type="number"
                              name="age"
                              value={this.state.age}
                              onChange={(e) => this.handleChange(e)}
                              id="age"
                              readOnly
                            ></Input>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              {translate("Prefecture")}
                            </label>
                            <Input
                              className="form-control-alternative"
                              type="text"
                              name="state"
                              value={this.state.state}
                              onChange={(e) => {
                                this.handleChange(e);
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              {translate("City")}
                            </label>
                            <Input
                              className="form-control-alternative"
                              type="text"
                              name="city"
                              value={this.state.city}
                              onChange={(e) => {
                                this.handleChange(e);
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              {translate("Address_Line_1")}
                            </label>
                            <Input
                              className="form-control-alternative"
                              type="text"
                              name="address_line1"
                              value={this.state.address_line1}
                              onChange={(e) => {
                                this.handleChange(e);
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              {translate("Address_Line_2")}
                            </label>
                            <Input
                              className="form-control-alternative"
                              type="text"
                              name="address_line2"
                              value={this.state.address_line2}
                              onChange={(e) => {
                                this.handleChange(e);
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              {translate("Building_name")}
                            </label>
                            <Input
                              className="form-control-alternative"
                              type="text"
                              name="building_name"
                              value={this.state.building_name}
                              onChange={(e) => {
                                this.handleChange(e);
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              {translate("Zip_code")}
                            </label>
                            <Input
                              className="form-control-alternative"
                              type="text"
                              name="zip_code"
                              value={this.state.zip_code}
                              onChange={(e) => {
                                this.handleChange(e);
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Line ID
                            </label>
                            <Input
                              className="form-control-alternative"
                              type="text"
                              name="line_number"
                              value={this.state.line_number}
                              onChange={(e) => {
                                this.handleChange(e);
                              }}
                              readOnly
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              {translate("Mobile_number")}
                            </label>
                            <Input
                              className="form-control-alternative"
                              type="text"
                              name="phone"
                              value={this.state.phone}
                              onChange={(e) => {
                                this.handleChange(e);
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row></Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              {translate("University")}
                            </label>
                            <Input
                              className="form-control-alternative"
                              type="text"
                              name="university"
                              value={this.state.university}
                              onChange={(e) => {
                                this.handleChange(e);
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              {translate("Graduation_date")}
                            </label>
                            <Input
                              className="form-control-alternative"
                              type="month"
                              name="graduation_date"
                              value={this.state.graduation_date_value}
                              max="9999-12"
                              onChange={(e) => {
                                e.preventDefault();
                                this.setState({
                                  graduation_date: e.target.value + "-01",
                                });
                                this.setState({
                                  graduation_date_value: e.target.value,
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              {translate("License_approval_date")}
                            </label>
                            <Input
                              className="form-control-alternative"
                              type="date"
                              name="license_approval_date"
                              max="9999-12-31"
                              value={this.state.license_approval_date}
                              onChange={(e) => {
                                this.handleChange(e);
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              {translate("Clinical_departmentD")}
                            </label>

                            {/* <Select
                              closeMenuOnSelect={false}
                              components={animatedComponents}
                              defaultValue={this.state.selectDefaultValue}
                              isMulti
                              options={clinical_department_value}
                              onChange={(e) => this.handleSelectChange(e)}
                              value={clinical_department_value.filter((obj) =>
                                this.state.dept.includes(obj.value)
                              )}
                            /> */}
                            <Select
                              defaultValue={this.demo(
                                this.state.clinicalselected,
                                clinical_department_value
                              )}
                              isMulti
                              name="clinical_department"
                              // value={this.state.clinical_department}
                              options={clinical_department_value}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              onChange={(event) => {
                                const values = [];

                                if (event !== null) {
                                  for (const entry of event.entries()) {
                                    const { value, label } = entry[1];

                                    values.push(label);
                                  }
                                }

                                this.setState({
                                  clinical_department: values,
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row></Row>
                    </div>
                    <hr className="my-4" />
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

// export default Profile;
const mapStateToProps = (state) => {
  return Object.assign({}, state.doctor, state.auth);
};

export default connect(mapStateToProps)(
  withRouter(withTranslate(HospitalsDetails))
);
