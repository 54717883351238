import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslate } from "react-redux-multilingual";
import { logout } from "../../actions/userActions";
import { IntlActions } from "react-redux-multilingual";
import store from "../../store/index";

const AdminNavbar = (props) => {
  const changeLanguage = (lang) => {
    store.dispatch(IntlActions.setLocale(lang));
  };
  const { translate } = props;
  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <Link
            className="h4 mb-0 text-black text-uppercase d-none d-lg-inline-block"
            to="/"
          >
            {props.brandText}
          </Link>
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  {/* <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="..."
                      src={
                        require("../../assets/img/theme/team-4-800x800.jpg")
                          .default
                      }
                    />
                  </span> */}
                  <span className="avatar avatar-sm rounded-circle">
                    <i className="ni ni-settings-gear-65" />
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span
                      className="mb-0 text-black text-sm font-weight-bold "
                      style={{ color: "black" }}
                    >
                      {translate("Settings")}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">{translate("Welcome")}!</h6>
                </DropdownItem>
                <DropdownItem to="/admin/index" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>{translate("My_profile")}</span>
                </DropdownItem>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">{translate("Language")}</h6>
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    changeLanguage("en");
                  }}
                >
                  <i className="ni ni-single-02" />
                  <span>{translate("English")}</span>
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    changeLanguage("jp");
                  }}
                >
                  <i className="ni ni-settings-gear-65" />
                  <span>{translate("Japanese")}</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem
                  href="#pablo"
                  onClick={(e) => props.dispatch(logout(props.history))}
                >
                  <i className="ni ni-user-run" />
                  <span>{translate("Logout")}</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

const mapStateToProps = (state) => {
  return Object.assign({});
};
export default connect(mapStateToProps)(withRouter(withTranslate(AdminNavbar)));
