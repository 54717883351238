const Module = {
  jp: {
    locale: "jp",
    messages: {
      Logout: "ログアウト",
      English: "English",
      Japanese: "日本語",
      Passsword_length_must_be_8_32_characters:
        "パスワードの長さは8〜32文字である必要があります",
      Login: "ログイン",
      Email_address: "メールアドレス",
      Password: "パスワード",
      Forgot: "忘れた",
      Invalid_email_address: "メールアドレスは半角英数字で入力してください",
      missing_field: "欠落しているフィールド",
      Number: "医師番号",
      NumberD: "医師番号",
      NumberH: "医療機関番号",
      Group_ID: "医師グループ番号",
      Group_Name: "医師グループ名",
      Number_of_group_members: "登録医師数",
      Explanation_of_group: "グループの説明",
      Actions: "操作",
      Add_Group: "医師グループの追加",
      Submit: "登録",
      Cancel: "キャンセル",
      Delete: "削除",
      last_name: "姓",
      last_name_y: "姓(フリガナ)",
      first_name: "名",
      first_name_y: "名(フリガナ)",
      gender: "性別",
      dob: "生年月日",
      Mobile_number: "携帯電話番号",
      My_account: "管理者アカウント",
      User_information: "ユーザー情報",
      Forgot_password: "パスワードをお忘れですか",
      Sign_in_with_credentials: "資格情報でサインインします",
      Add_Doctor: "医師の追加",
      edit: "編集",
      Email_ID: "担当者のメールアドレス",
      Email_IDD: "メールアドレス",
      Email_IDH: "担当者のメールアドレス",
      Prefecture: "都道府県",
      City: "都市",
      Address_Line_1: "住所1",
      Address_Line_2: "住所2",
      Building_name: "建物名",
      Zip_code: "〒",
      Age: "年齢",
      University: "卒業大学",
      Graduation_date: "大学卒業年月",
      License_approval_date: "医師免許取得年月",
      Clinical_department: "対応可能な診療科",
      Clinical_departmentD: "対応可能な診療科",
      Clinical_departmentH: "募集診療科",
      Photo: "顔写真",
      Delete_Window: "削除確認",
      Are_you_sure_You_want_to_delete_this: "削除してもよろしいですか",
      Hospital_name: "医療機関名",
      Contact_person_name: "担当者名",
      Contact_person_phone: "担当者の携帯電話番号",
      Add_Hospital: "医療機関の追加",
      Phone: "代表電話番号",
      Holidays: "休日",
      Distance_from_Station: "アクセス",
      Nearest_Station: "最寄り駅",
      Add_Doctor_Group: "医師グループの追加",
      Line_Notification: "LINE通知",
      Select_Doctors_Group: "医師グループの選択",
      Select_Doctors: "医師の選択",
      Message: "メッセージ",
      Send_Message: "メッセージ送信",
      Hospital_ID: "医療機関番号",
      Hospital_Name: "医療機関名",
      Number_of_times: "募集案件数/月",
      Total_amount: "総請求金額/月",
      Status: "ステータス",
      View_Buttons: "勤務実績",
      View_Buttons1: "勤務実績",
      issue_invoice: "請求書発行",
      Invoice: "請求書",
      View_List: "リストを表示",
      View_List1: "表示",
      Doctor_profile: "医師登録情報",
      Update: "更新",
      Hospital_profile: "医療機関登録情報",
      Add_Doc: "医師の追加",
      No_item: "登録済みの医師はいません",
      Add: "追加",
      Logout: "ログアウト",
      Settings: "設定",
      Welcome: "ようこそ！",
      Language: "言語",
      My_profile: "登録情報",
      Job_Code: "ジョブコード",
      doctor_name: "医師名",
      Start_work_date: "勤務開始日",
      Work_start: "勤務開始時刻",
      Work_finish: "勤務終了時刻",
      total_work_hours: "勤務時間",
      Approve: "承認",
      Not_Approve: "申請済・未承認 ",
      Return: "戻る",
      Dashboard: "ダッシュボード",
      Doctor: "医師",
      Hospitals: "医療機関",
      Doctors_Group: "医師グループ",
      Line_Notification: "LINE通知",
      Hospital_Details: "医療機関の詳細",
      Doctor_Details: "医師詳細",
      Doctors_List: "医師リスト",
      Hospital_Doctor_List: "医療機関の医師リスト",
      Last_name_doctor: "姓",
      Last_name_katakana: "姓(フリガナ)",
      First_name_doctor: "名",
      First_name_katakana: "名(フリガナ)",
      Doctors_no: "医師番号",
    },
  },
  en: {
    locale: "en",
    messages: {
      Logout: "Logout",
      English: "English",
      Japanese: "日本語",
      Passsword_length_must_be_8_32_characters:
        "Passsword length must be 8-20 characters",
      Login: "Login",
      Email_address: "Email",
      Password: "Password",
      Forgot: "Forgot",
      Invalid_email_address: "Invalid email address",
      missing_field: "missing field",
      Number: "Number",
      NumberD: "Number",
      NumberH: "Number",
      Group_ID: "Group ID",
      Group_Name: "Group Name",
      Number_of_group_members: "Number of group members",
      Explanation_of_group: "Explanation of group",
      Actions: "Actions",
      Add_Group: "Add Group",
      Submit: "Submit",
      Cancel: "Cancel",
      Delete: "Delete",
      last_name: "Last name",
      last_name_y: "Last name Yomigana",
      first_name: "First name",
      first_name_y: "First name Yomigana",
      gender: "Gender",
      dob: "Date of Birth",
      Mobile_number: "Mobile phone no.",
      My_account: "My account",
      User_information: "User information",
      Forgot_password: "Forgot password",
      Sign_in_with_credentials: "Sign in with credentials",
      Add_Doctor: "Add Doctor",
      edit: "Edit",
      Email_ID: "Email (ID)",
      Email_IDD: "Email (ID)",
      Email_IDH: "Email (ID)",
      Prefecture: "Prefecture",
      City: "City",
      Address_Line_1: "Address Line 1",
      Address_Line_2: "Address Line 2",
      Building_name: "Building name",
      Zip_code: "Zip code",
      Age: "Age",
      University: "University",
      Graduation_date: "Graduation month",
      License_approval_date: "License approval date",
      Clinical_department: "Clinical department",
      Clinical_departmentD: "Clinical department",
      Clinical_departmentH: "Clinical department",
      Photo: "Photo",
      Delete_Window: "Delete confirmation",
      Are_you_sure_You_want_to_delete_this: "Are you sure to delete this?",
      Hospital_name: "Hospital name",
      Contact_person_name: "Contact person name",
      Contact_person_phone: "Contact person's mobile phone",
      Add_Hospital: "Add Hospital",
      Phone: "Phone",
      Holidays: "Holidays",
      Distance_from_Station: "Access",
      Nearest_Station: "Nearest Station",
      Add_Doctor_Group: "Add Doctor Group",
      Line_Notification: "Line Notification",
      Select_Doctors_Group: "Select Doctors' Group",
      Select_Doctors: "Select Doctors",
      Message: "Message",
      Send_Message: "Send Message",
      Hospital_ID: "Hospital ID",
      Hospital_Name: "Hospital Name",
      Number_of_times: "Number of times",
      Total_amount: "Total amount",
      Status: "Status",
      View_Buttons: "View Buttons",
      View_Buttons1: "SERVICE RECORD",
      issue_invoice: "issue invoice",
      Invoice: "Invoice",
      View_List: "View List",
      View_List1: "View List",
      Doctor_profile: "Doctor profile",
      Update: "Update",
      Hospital_profile: "Hospital profile",
      Add_Doc: " Add Doc",
      No_item: " No item",
      Add: "Add",
      Logout: "Logout",
      Settings: "Settings",
      Welcome: "Welcome",
      Language: "Language",
      My_profile: "My profile",
      Job_Code: "Job_Code",
      doctor_name: "Doctor name",
      Start_work_date: "Start_work_date",
      Work_start: "Work start",
      Work_finish: "Work finish",
      total_work_hours: "Total work hours",
      Approve: "Approve",
      Not_Approve: "Not Approve",
      Return: "Return",
      Dashboard: "Dashboard",
      Doctor: "Doctor",
      Hospitals: "Hospitals",
      Doctors_Group: "Doctors Group",
      Line_Notification: "Line Notification",
      Hospital_Details: "Hospital Details",
      Doctor_Details: "Doctor Details",
      Doctors_List: "Doctors' List",
      Hospital_Doctor_List: "Hospital Doctor List",
      Last_name_doctor: "Last name",
      Last_name_katakana: "Last name (katakana)",
      First_name_doctor: "First name",
      First_name_katakana: "First name (katakana)",
      Doctors_no: "Doctors_no",
    },
  },
};
export default Module;
