import {
  USER_LOGIN_START,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT_SUCCESS,
  USER_ME_START,
  USER_ME_SUCCESS,
  USER_ME_FAILURE,
  HOSPITAL_IMAGE_START,
  HOSPITAL_IMAGE_SUCCESS,
  HOSPITAL_IMAGE_FAILURE,
} from "../constants/ActionTypes";

const initialState = {
  isLoggedIn: false,
  authInfo: "",
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case USER_LOGIN_START:
      return Object.assign({}, state, action.payload);
    case USER_LOGIN_SUCCESS:
      return Object.assign({}, state, action.payload);
    case USER_LOGOUT_SUCCESS:
      return Object.assign({}, state, action.payload);
    case USER_ME_START:
      return Object.assign({}, state, action.payload);
    case USER_ME_SUCCESS:
      return Object.assign({}, state, action.payload);
    case USER_ME_FAILURE:
      return Object.assign({}, state, action.payload);
    case HOSPITAL_IMAGE_START:
      return Object.assign({}, state, action.payload);
    case HOSPITAL_IMAGE_SUCCESS:
      return Object.assign({}, state, action.payload);
    case HOSPITAL_IMAGE_FAILURE:
      return Object.assign({}, state, action.payload);
    default:
  }
  return state;
}
