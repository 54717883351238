import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslate } from "react-redux-multilingual";
import {
  validateEmail,
  validateNull,
  validatePassword,
} from "../../services/validation";
import {
  hospitalUpdate,
  fetchSingleHospital,
} from "../../actions/hospitalActions";
import { toast } from "react-toastify";
import { clinical_department_value } from "../../constants/clinicalDepartment";
import Select from "react-select";
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();

class HospitalsDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dept: [],
      hospitalvalue: null,
      clinicalselected: [],
    };
  }

  componentDidMount() {
    if (this.props.location.state !== undefined) {
      this.props.fetchSingleHospital(
        this.props.auth.authInfo,
        this.props.history,
        this.props.location.state
      );
      const hospitalinfo = this.props.hospital?.fetchSingleHospitalInfo;
      console.log("hospital", hospitalinfo);
      let uniqueclinical_department = [];

      if (
        hospitalinfo?.clinical_department &&
        hospitalinfo?.clinical_department.length > 0
      ) {
        uniqueclinical_department = new Set(
          hospitalinfo?.clinical_department.split(",")
        );
        uniqueclinical_department = [...uniqueclinical_department];
      }
      this.setState({
        hospitalid: this.props.hospital?.fetchSingleHospitalInfo?.id,
        // page: this.props.hospital?.fetchSingleHospitalInfo.page,
        name: this.props.hospital?.fetchSingleHospitalInfo?.name,
        contact_person: this.props.hospital?.fetchSingleHospitalInfo
          ?.contact_person,
        contact_person_phone: this.props.hospital?.fetchSingleHospitalInfo
          ?.contact_person_phone,
        email: this.props.hospital?.fetchSingleHospitalInfo?.email,
        state: this.props.hospital?.fetchSingleHospitalInfo?.state,
        city: this.props.hospital?.fetchSingleHospitalInfo?.city,
        image: this.props.hospital?.fetchSingleHospitalInfo?.image,
        address_line1: this.props.hospital?.fetchSingleHospitalInfo
          ?.address_line1,
        address_line2: this.props.hospital?.fetchSingleHospitalInfo
          ?.address_line2,
        building_name: this.props.hospital?.fetchSingleHospitalInfo
          ?.building_name,
        zip_code: this.props.hospital?.fetchSingleHospitalInfo?.zip_code,
        nearest_station: this.props.hospital?.fetchSingleHospitalInfo
          ?.nearest_station,
        dist_from_station: this.props.hospital?.fetchSingleHospitalInfo
          ?.dist_from_station,
        holidays: this.props.hospital?.fetchSingleHospitalInfo?.holidays,
        phone: this.props.hospital?.fetchSingleHospitalInfo?.phone,
        line_number: this.props.hospital?.fetchSingleHospitalInfo?.line_number,
        password: "",
        send_group: this.props.hospital?.fetchSingleHospitalInfo?.send_group,
        clinical_department: this.props.hospital?.fetchSingleHospitalInfo
          ?.clinical_department,
        clinicalselected: uniqueclinical_department,
      });
    } else {
      this.props.history.push("/admin/maps");
    }
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }
  handleSelectChange = (e) => {
    this.setState({
      dept: Array.isArray(e) ? e.map((x) => x.value) : [],
    });
  };

  removeEmpty = (state) => {
    Object.keys(state).forEach(
      (k) => !state[k] && state[k] !== undefined && delete state[k]
    );

    return state;
  };
  handleSubmit = async (e) => {
    const { translate } = this.props;

    e.preventDefault();
    let dept = this.state.clinical_department;
    if (dept === null) {
      dept = "";
    } else if (typeof this.state.clinical_department == "object") {
      dept = this.state.clinical_department.join();
    }
    let data = {
      name: this.state.name,
      contact_person: this.state.contact_person,
      contact_person_phone: this.state.contact_person_phone,
      email: this.state.email,
      state: this.state.state,
      city: this.state.city,
      address_line1: this.state.address_line1,
      address_line2: this.state.address_line2,
      building_name: this.state.building_name,
      zip_code: this.state.zip_code,
      nearest_station: this.state.nearest_station,
      dist_from_station: this.state.dist_from_station,
      clinical_department: dept,
      holidays: this.state.holidays,
      phone: this.state.phone,
      line_number: this.state.line_number,
      password: this.state.password,
      send_group: this.state.send_group,
    };
    let hd = await this.removeEmpty(data);
    if (
      validateEmail(this.state.email) &&
      ((this.state.password.length > 0 &&
        validatePassword(this.state.password)) ||
        this.state.password.length === 0) &&
      validateNull(this.state.name) &&
      validateNull(this.state.contact_person) &&
      validateNull(this.state.contact_person_phone) &&
      validateNull(this.state.phone)
    ) {
      this.props.hospitalUpdate(
        hd,
        this.props.auth.authInfo,
        this.props.history,
        this.props.location.state
      );
    } else {
      if (validateEmail(this.state.email) === false) {
        toast.error(translate("Invalid_email_address"));
      }
      if (this.state.password.length > 0) {
        if (validatePassword(this.state.password) === false) {
          toast.error("Password is not valid");
        }
      }
      if (validateNull(this.state.contact_person) === false) {
        toast.error(translate("missing_field") + " Contact Person");
      }
      if (validateNull(this.state.name) === false) {
        toast.error(translate("missing_field") + " Hospital Name");
      }
      if (validateNull(this.state.contact_person_phone) === false) {
        toast.error(translate("missing_field") + " Contact Person Name");
      }
      if (validateNull(this.state.phone) === false) {
        toast.error(translate("missing_field") + " Mobile Number");
      }
      if (validateNull(this.state.line_number) === false) {
        toast.error(translate("missing_field") + " Line ID");
      }
    }
  };

  componentWillReceiveProps(newprops) {
    if (this.state.hospitalvalue != newprops.hospital.fetchSingleHospitalInfo) {
      let uniqueclinical_department = [];

      if (
        newprops.hospital.fetchSingleHospitalInfo.clinical_department &&
        newprops.hospital.fetchSingleHospitalInfo.clinical_department.length > 0
      ) {
        uniqueclinical_department = new Set(
          newprops.hospital.fetchSingleHospitalInfo.clinical_department.split(
            ","
          )
        );
        uniqueclinical_department = [...uniqueclinical_department];
      }
      this.setState({
        hospitalvalue: newprops.hospital.fetchSingleHospitalInfo,
        hospitalid: newprops.hospital.fetchSingleHospitalInfo.id,
        // page: newprops.hospital.fetchSingleHospitalInfo.page,
        name: newprops.hospital.fetchSingleHospitalInfo.name,
        contact_person:
          newprops.hospital.fetchSingleHospitalInfo.contact_person,
        contact_person_phone:
          newprops.hospital.fetchSingleHospitalInfo.contact_person_phone,
        email: newprops.hospital.fetchSingleHospitalInfo.email,
        state: newprops.hospital.fetchSingleHospitalInfo.state,
        city: newprops.hospital.fetchSingleHospitalInfo.city,
        image: newprops.hospital.fetchSingleHospitalInfo.image,
        address_line1: newprops.hospital.fetchSingleHospitalInfo.address_line1,
        address_line2: newprops.hospital.fetchSingleHospitalInfo.address_line2,
        building_name: newprops.hospital.fetchSingleHospitalInfo.building_name,
        zip_code: newprops.hospital.fetchSingleHospitalInfo.zip_code,
        nearest_station:
          newprops.hospital.fetchSingleHospitalInfo.nearest_station,
        dist_from_station:
          newprops.hospital.fetchSingleHospitalInfo.dist_from_station,
        holidays: newprops.hospital.fetchSingleHospitalInfo.holidays,
        phone: newprops.hospital.fetchSingleHospitalInfo.phone,
        line_number: newprops.hospital.fetchSingleHospitalInfo.line_number,
        password: "",
        send_group: newprops.hospital.fetchSingleHospitalInfo.send_group,
        clinicalselected: uniqueclinical_department,
        clinical_department:
          newprops.hospital.fetchSingleHospitalInfo.clinical_department,
      });
    } else {
      // this.props.history.push("/admin/maps");
    }
  }

  demo(arr1, arr2) {
    const value = [];
    for (const val of arr2) {
      if (arr1.includes(val.label)) {
        value.push(val);
      }
    }

    return value;
  }
  render() {
    const { translate } = this.props;

    const hospitalinfo = this.props.hospital.fetchSingleHospitalInfo;

    return (
      <>
        <div className="pb-6 pt-2 pt-md-8"></div>

        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">{translate("Hospital_profile")}</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button
                        color="primary"
                        href="#pablo"
                        onClick={(e) => this.handleSubmit(e)}
                        size="sm"
                      >
                        {translate("Update")}
                      </Button>
                      <Button
                        color="info"
                        onClick={(e) =>
                          this.props.history.push({
                            pathname: "/admin/maps",
                            page: this.state.page,
                          })
                        }
                        size="sm"
                      >
                        {translate("Cancel")}
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          {this.state.image ? (
                            <img
                              alt="hos_img"
                              className="form-control"
                              src={this.state.image}
                              style={{ height: "auto" }}
                            />
                          ) : null}
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              {translate("Hospital_name")}
                            </label>
                            <Input
                              className="form-control-alternative"
                              type="text"
                              name="name"
                              value={this.state.name}
                              onChange={(e) => this.handleChange(e)}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              {translate("Email_IDH")}
                            </label>
                            <Input
                              className="form-control-alternative"
                              type="email"
                              name="email"
                              value={this.state.email}
                              onChange={(e) => this.handleChange(e)}
                              disabled
                              style={{ backgroundColor: "white" }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              {translate("Contact_person_name")}
                            </label>
                            <Input
                              className="form-control-alternative"
                              type="text"
                              value={this.state.contact_person}
                              name="contact_person"
                              onChange={(e) => this.handleChange(e)}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              {translate("Contact_person_phone")}
                            </label>
                            <Input
                              className="form-control-alternative"
                              type="text"
                              name="contact_person_phone"
                              value={this.state.contact_person_phone}
                              onChange={(e) => this.handleChange(e)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              {translate("Prefecture")}
                            </label>
                            <Input
                              className="form-control-alternative"
                              type="text"
                              name="state"
                              value={this.state.state}
                              onChange={(e) => {
                                this.handleChange(e);
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              {translate("City")}
                            </label>
                            <Input
                              className="form-control-alternative"
                              type="text"
                              name="city"
                              value={this.state.city}
                              onChange={(e) => {
                                this.handleChange(e);
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              {translate("Address_Line_1")}
                            </label>
                            <Input
                              className="form-control-alternative"
                              type="text"
                              name="address_line1"
                              value={this.state.address_line1}
                              onChange={(e) => {
                                this.handleChange(e);
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              {translate("Address_Line_2")}
                            </label>
                            <Input
                              className="form-control-alternative"
                              type="text"
                              name="address_line2"
                              value={this.state.address_line2}
                              onChange={(e) => {
                                this.handleChange(e);
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              {translate("Building_name")}
                            </label>
                            <Input
                              className="form-control-alternative"
                              type="text"
                              name="building_name"
                              value={this.state.building_name}
                              onChange={(e) => {
                                this.handleChange(e);
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              {translate("Zip_code")}
                            </label>
                            <Input
                              className="form-control-alternative"
                              type="text"
                              name="zip_code"
                              value={this.state.zip_code}
                              onChange={(e) => {
                                this.handleChange(e);
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Line ID
                            </label>
                            <Input
                              className="form-control-alternative"
                              type="text"
                              name="line_number"
                              value={this.state.line_number}
                              onChange={(e) => {
                                this.handleChange(e);
                              }}
                              readOnly
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              {translate("Password")}
                            </label>
                            <Input
                              className="form-control-alternative"
                              name="password"
                              value={this.state.password}
                              onChange={(e) => {
                                this.handleChange(e);
                              }}
                              type="password"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              {translate("Clinical_departmentH")}
                            </label>
                            <Select
                              defaultValue={this.demo(
                                this.state.clinicalselected,
                                clinical_department_value
                              )}
                              isMulti
                              name="clinical_department"
                              // value={this.state.clinical_department}
                              options={clinical_department_value}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              onChange={(event) => {
                                const values = [];

                                if (event !== null) {
                                  for (const entry of event.entries()) {
                                    const { value, label } = entry[1];

                                    values.push(label);
                                  }
                                }

                                this.setState({
                                  clinical_department: values,
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              {translate("Phone")}
                            </label>
                            <Input
                              className="form-control-alternative"
                              type="text"
                              name="phone"
                              value={this.state.phone}
                              onChange={(e) => {
                                this.handleChange(e);
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        {/* <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              {translate("Nearest_Station")}
                            </label>
                            <Input
                              className="form-control-alternative"
                              type="text"
                              name="nearest_station"
                              value={this.state.nearest_station}
                              onChange={(e) => {
                                this.handleChange(e);
                              }}
                            />
                          </FormGroup>
                        </Col> */}
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              {translate("Distance_from_Station")}
                            </label>
                            <Input
                              className="form-control-alternative"
                              type="text"
                              name="dist_from_station"
                              value={this.state.dist_from_station}
                              onChange={(e) => {
                                this.handleChange(e);
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              {translate("Holidays")}
                            </label>
                            <Input
                              className="form-control-alternative"
                              type="text"
                              name="holidays"
                              value={this.state.holidays}
                              onChange={(e) => {
                                this.handleChange(e);
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        {/* <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              {translate("Holidays")}
                            </label>
                            <Input
                              className="form-control-alternative"
                              type="text"
                              name="holidays"
                              value={this.state.holidays}
                              onChange={(e) => {
                                this.handleChange(e);
                              }}
                            />
                          </FormGroup>
                        </Col> */}
                      </Row>
                      <Row></Row>
                    </div>
                    <hr className="my-4" />
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

// export default Profile;
// const mapStateToProps = (state) => {
//   return Object.assign({}, state.hospital, state.auth);
// };
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    hospital: state.hospital,
  };
};
export default connect(mapStateToProps, {
  fetchSingleHospital,
  hospitalUpdate,
})(withRouter(withTranslate(HospitalsDetails)));
