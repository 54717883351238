import React from "react";
import { Table } from "reactstrap";
import {
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslate } from "react-redux-multilingual";
import { validateNull } from "../../services/validation";
import { toast } from "react-toastify";
import {
  fetchGroups,
  deletegroup,
  groupCreate,
} from "../../actions/groupActions";
import Pagination from "@material-ui/lab/Pagination";
import LoadingOverlay from "react-loading-overlay";

class DoctorsGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      deleteModal: false,
      deleteId: "",
    };
  }
  componentWillMount() {
    this.props.dispatch(
      fetchGroups(
        this.props.authInfo,
        this.props.history,
        1,
        "group_name",
        "asc"
      )
    );
  }
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };
  toggleDelete = (id) => {
    this.setState({ deleteModal: !this.state.deleteModal });
    if (id) {
      this.setState({ deleteId: id });
    }
  };
  deleteAction = () => {
    this.props.dispatch(
      deletegroup(
        this.props.authInfo,
        this.props.history,
        this.state.deleteId,
        "grp"
      )
    );
    //after delete
    this.setState({ deleteModal: !this.state.deleteModal });
    this.setState({ deleteId: "" });
  };
  handleSubmit = (e) => {
    const { translate } = this.props;
    e.preventDefault();
    let data = {
      group_name: this.state.group_name,
      description: this.state.description,
      created_by: this.props.authInfo.user_info.id,
      is_active: 1,
    };
    if (
      validateNull(this.state.group_name) &&
      validateNull(this.state.description)
    ) {
      this.props.dispatch(
        groupCreate(data, this.props.authInfo, this.props.history)
      );
    } else {
      if (validateNull(this.state.group_name) === false) {
        toast.error(translate("missing_field") + " Group Name");
      }
      if (validateNull(this.state.description) === false) {
        toast.error(translate("missing_field") + " Explanation of group");
      }
    }
  };
  detailsRedirect = (data) => {
    this.props.history.push({
      pathname: "/admin/doctor-group-details",
      state: data,
    });
  };
  listRedirect = (data) => {
    this.props.history.push({
      pathname: "/admin/doctors-group/list",
      state: data,
    });
  };
  render() {
    const { translate } = this.props;
    return (
      <>
        <div className="pb-6 pt-2 pt-md-8"></div>

        <Container className="mt--6" fluid>
          <LoadingOverlay
            active={this.props.fetchGroupLoading}
            spinner
            text="Loading..."
          >
            <Button
              color="primary"
              style={{ float: "right", margin: "1rem" }}
              onClick={() => this.toggle()}
            >
              <i className="fas fa-plus"></i> &nbsp;{" "}
              {translate("Add_Doctor_Group")}
            </Button>
            <Table striped>
              <thead>
                <tr>
                  <th>{translate("Group_ID")}</th>
                  <th>{translate("Group_Name")}</th>
                  <th>{translate("Number_of_group_members")}</th>
                  <th>{translate("Explanation_of_group")}</th>
                  <th>{translate("Actions")}</th>
                </tr>
              </thead>

              <tbody>
                {this.props.groups
                  ? this.props.groups.data.map((item, i) => (
                      <tr>
                        <th scope="row">{item.id}</th>
                        <td>{item.group_name}</td>
                        <td>{item.number_of_members}</td>
                        <td>{item.description}</td>
                        <td>
                          <Button
                            color="info"
                            onClick={() => this.detailsRedirect(item)}
                            title="Edit"
                          >
                            <i className="fas fa-edit"></i>
                          </Button>
                          <Button
                            color="danger"
                            onClick={() => this.toggleDelete(item.id)}
                            title="Delete"
                          >
                            <i className="far fa-trash-alt"></i>
                          </Button>
                          <Button
                            color="primary"
                            onClick={() => this.listRedirect(item)}
                            title="Doc. List"
                          >
                            <i className="fas fa-list"></i>
                          </Button>
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </Table>
          </LoadingOverlay>
          <div className="container d-flex justify-content-center">
            {this.props.groups != undefined ? (
              <Pagination
                count={this.props.groups.pagination.last_page}
                onChange={(event, page) => {
                  this.props.dispatch(
                    fetchGroups(
                      this.props.authInfo,
                      this.props.history,
                      page,
                      "group_name",
                      "asc"
                    )
                  );
                }}
                showFirstButton
                showLastButton
                variant="outlined"
                shape="rounded"
              />
            ) : null}
          </div>

          <Modal
            isOpen={this.state.modal}
            toggle={() => this.toggle()}
            className={this.className}
          >
            <ModalHeader toggle={() => this.toggle()}>
              {translate("Add_Group")}
            </ModalHeader>
            <ModalBody>
              <Form>
                <FormGroup>
                  <Label for="fullName">{translate("Group_Name")}</Label>
                  <Input
                    type="text"
                    name="group_name"
                    value={this.state.group_name}
                    onChange={(e) => this.handleChange(e)}
                    id="fullName"
                    placeholder={translate("Group_Name")}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="country">
                    {translate("Explanation_of_group")}
                  </Label>
                  <Input
                    type="text"
                    name="description"
                    value={this.state.description}
                    onChange={(e) => this.handleChange(e)}
                    id="country"
                    placeholder={translate("Explanation_of_group")}
                  />
                </FormGroup>
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={(e) => this.handleSubmit(e)}>
                {translate("Submit")}
              </Button>{" "}
              <Button color="secondary" onClick={() => this.toggle()}>
                {translate("Cancel")}
              </Button>
            </ModalFooter>
          </Modal>

          <Modal
            isOpen={this.state.deleteModal}
            toggle={() => this.toggleDelete()}
            className={this.className}
          >
            <ModalHeader>{translate("Delete_Window")}</ModalHeader>
            <ModalBody>
              {translate("")}
              {translate("Are_you_sure_You_want_to_delete_this")}!
            </ModalBody>
            <ModalFooter>
              <Button color="danger" onClick={() => this.deleteAction()}>
                {translate("Delete")}
              </Button>{" "}
              <Button color="secondary" onClick={() => this.toggleDelete()}>
                {translate("Cancel")}
              </Button>
            </ModalFooter>
          </Modal>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return Object.assign({}, state.group, state.auth);
};

export default connect(mapStateToProps)(
  withRouter(withTranslate(DoctorsGroup))
);
