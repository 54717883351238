import React from "react";
import { Table } from "reactstrap";
import { Container, Button, Row, Col, FormGroup, Input } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslate } from "react-redux-multilingual";
import { fetchInvoices, pdfInvoice } from "../../actions/invoiceActions";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import Pagination from "@material-ui/lab/Pagination";
import LoadingOverlay from "react-loading-overlay";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import ComponentToPrint from "./invoice_pdf";
class Invoice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pdf: "",
      invoice_pdf: null,
      hos_data: null,
      year: "",
      month: "",
      invoices: null,
      selected_date: new Date()
    };
  }
  componentWillMount() {
    let d = new Date();
    this.setState({ year: d.getFullYear(), month: d.getMonth() + 1 });
    this.props.dispatch(
      fetchInvoices(
        this.props.authInfo,
        this.props.history,
        d.getFullYear(),
        d.getMonth() + 1,
        1,
        ""
      )
    );
  }
  listRedirect = (data) => {
    this.props.history.push({
      pathname: "/admin/invoice-doc-list",
      state: data,
      year: this.state.year,
      month: this.state.month,
    });
  };
  handlePrint = (item) => {
    let d = new Date();
    let data = {
      name: item.hospital_name,
      // hospital_name_yomigana: item.hospital_name_yomigana,
      // num_of_doctor: item.num_of_doctor,
      // in_total_working_day: item.in_total_working_day,
      date: d.getDate(),
      month: this.state.month,
      year: this.state.year,
    };
    this.setState({ hos_data: data });
    this.props.dispatch(
      pdfInvoice(
        this.props.authInfo,
        this.props.history,
        this.state.year,
        this.state.month,
        item.hospital_id
      )
    );
    setTimeout(() => {
      this.setState({ invoice_pdf: item.hospital_id });
    }, 2000);
  };
  componentWillReceiveProps(newProps) {
    if (this.state.pdf != newProps.pdfInvoice) {
      this.setState({ pdf: newProps.pdfInvoice });
    }
    if (this.state.invoices != newProps.invoices) {
      this.setState({ invoices: newProps.invoices });
    }
  }
  handleCalender = (e) => {
    this.setState({
      year: e.target.value.split("-")[0],
      month: e.target.value.split("-")[1],
    });
    this.props.dispatch(
      fetchInvoices(
        this.props.authInfo,
        this.props.history,
        e.target.value.split("-")[0],
        e.target.value.split("-")[1],
        1,
        ""
      )
    );
  };
  setInvoiceDate = (date) => {
    this.setState({selected_date: date, year: date.getFullYear(), month: date.getMonth() + 1 });
    this.props.dispatch(
      fetchInvoices(
        this.props.authInfo,
        this.props.history,
        date.getFullYear(),
        parseInt(date.getMonth()) + 1,
        1,
        ""
      )
    );
  };

  render() {
    const { translate } = this.props;
    // console.log(this.state.selected_date)
    return (
      <>
        {/* <Header /> */}
        <div className="pb-8 pt-2 pt-md-4"></div>

        <Container className="mt--6" fluid>
          <LoadingOverlay
            active={this.props.fetchinvoicesLoading}
            spinner
            text="Loading..."
          >
            <Row className="align-items-center">
              <Col xs="3"></Col>
              <Col xs="5" style={{ textAlign: "center" }}>
                <FormGroup>
                  {/* <Input
                    className="form-control-alternative"
                    type="month"
                    name="name"
                    value={this.state.year + "-" + this.state.month}
                    onChange={(e) => this.handleCalender(e)}
                  /> */}
                  <DatePicker
                    selected={this.state.selected_date}
                    onChange={(date) => this.setInvoiceDate(date)}
                    // onSelect={(date) => this.setInvoiceDate(date)}
                    dateFormat="yyyy-MM"
                    showMonthYearPicker
                  />
                </FormGroup>
              </Col>
            </Row>
            <br />
            <Table striped>
              <thead>
                <tr>
                  <th>{translate("Hospital_ID")}</th>
                  <th>{translate("Hospital_Name")}</th>
                  <th>{translate("Number_of_times")}</th>
                  <th>{translate("Total_amount")}</th>
                  <th>{translate("Status")}</th>
                  <th>{translate("View_Buttons1")}</th>
                  <th>{translate("issue_invoice")}</th>
                </tr>
              </thead>
              <tbody>
                {this.state.invoices
                  ? this.state.invoices.data.map((item, i) => (
                      <tr>
                        <td>{item.hospital_id}</td>
                        <th>{item.hospital_name}</th>
                        <td>{item.num_of_times}</td>
                        <td>{item.total_amount}</td>
                        <td>
                          {item.is_approved == 1
                            ? "Approved"
                            : "Not approved yet"}
                        </td>
                        <td>
                          <Button
                            onClick={() => this.listRedirect(item.hospital_id)}
                          >
                            {translate("View_List1")}
                          </Button>
                        </td>
                        <td>
                          {!this.state.invoice_pdf ||
                          this.state.invoice_pdf !== item.hospital_id ? (
                            item.is_approved === 1 ? (
                              <Button
                                onClick={() => this.handlePrint(item)}
                                color="info"
                              >
                                {translate("Invoice")}
                              </Button>
                            ) : (
                              <Button disabled color="white">
                                {translate("Invoice")}
                              </Button>
                            )
                          ) : (
                            <div>
                              <ReactToPrint content={() => this.componentRef}>
                                <PrintContextConsumer>
                                  {({ handlePrint }) => (
                                    <Button color="info" onClick={handlePrint}>
                                      印刷
                                    </Button>
                                  )}
                                </PrintContextConsumer>
                              </ReactToPrint>
                              <div style={{ display: "none" }}>
                                <ComponentToPrint
                                  data={this.state.pdf}
                                  hos_data={this.state.hos_data}
                                  ref={(el) => (this.componentRef = el)}
                                />
                              </div>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
              <br />
              <br />
            </Table>
          </LoadingOverlay>
          <div className="container d-flex justify-content-center">
            {this.state.invoices != undefined ? (
              <Pagination
                count={this.state.invoices.pagination.last_page}
                onChange={(event, page) => {
                  let d = new Date();
                  this.props.dispatch(
                    fetchInvoices(
                      this.props.authInfo,
                      this.props.history,
                      d.getFullYear(),
                      d.getMonth() + 1,
                      page,
                      ""
                    )
                  );
                }}
                showFirstButton
                showLastButton
                variant="outlined"
                shape="rounded"
              />
            ) : null}
          </div>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return Object.assign({}, state.invoice, state.auth);
};

export default connect(mapStateToProps)(withRouter(withTranslate(Invoice)));
