import React from "react";
import { Table } from "reactstrap";
import {
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslate } from "react-redux-multilingual";
import {
  validateEmail,
  validateNull,
  validatePassword,
} from "../../services/validation";
import {
  fetchHospitals,
  hospitalCreate,
  hospitalUpdate,
} from "../../actions/hospitalActions";
import Pagination from "@material-ui/lab/Pagination";
import { toast } from "react-toastify";
import LoadingOverlay from "react-loading-overlay";
import { clinical_department_value } from "../../constants/clinicalDepartment";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { ImageUpload } from "../../actions/userActions";
const animatedComponents = makeAnimated();

class Hospitals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      deleteModal: false,
      password: "",
      send_group: "",
      page: 1,
      dept: [],
      image: null,
      email: "",
    };
  }

  componentWillMount() {
    if (this.props.location.page != undefined) {
      this.setState({ page: this.props.location.page });
      this.props.dispatch(
        fetchHospitals(
          this.props.authInfo,
          this.props.history,
          this.props.location.page,
          "id",
          "desc"
        )
      );
    } else {
      this.props.dispatch(
        fetchHospitals(this.props.authInfo, this.props.history, 1, "id", "desc")
      );
    }
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSelectChange = (e) => {
    this.setState({
      dept: Array.isArray(e) ? e.map((x) => x.value) : [],
    });
  };
  // handleImageChange = (event) => {
  //   if (event.target.files && event.target.files.length > 0) {
  //     this.setState({
  //       image: event.target.files[0],
  //     });
  //   } else {
  //     this.setState({
  //       image: null,
  //     });
  //   }
  // };

  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };
  toggleDelete = (id) => {
    if (this.state.deleteModal === false) {
      this.setState({ deleteId: id });
    }
    this.setState({ deleteModal: !this.state.deleteModal });
  };
  deleteAction = () => {
    this.state.deleteId["is_active"] = 0;
    this.props.dispatch(
      hospitalUpdate(
        this.state.deleteId,
        this.props.authInfo,
        this.props.history,
        this.state.deleteId.id
      )
    );
    // after delete
    this.setState({ deleteId: "" });
    setTimeout(function () {
      window.location.reload();
    }, 2000);
  };
  detailsRedirect = (hospitalid, p) => {
    this.props.history.push({
      pathname: "/admin/hospital-details",
      state: hospitalid,
      page: p,
    });
  };

  handleSubmit = (e) => {
    const { translate } = this.props;
    e.preventDefault();
    let data = {
      name: this.state.name,
      contact_person: this.state.contact_person,
      contact_person_phone: this.state.contact_person_phone,
      email: this.state.email,
      state: this.state.state,
      city: this.state.city,
      address_line1: this.state.address_line1,
      address_line2: this.state.address_line2,
      building_name: this.state.building_name,
      zip_code: this.state.zip_code,
      nearest_station: this.state.nearest_station,
      dist_from_station: this.state.dist_from_station,
      clinical_department: this.state.dept.join(),
      holidays: this.state.holidays,
      phone: this.state.phone,
      password: this.state.password,
      send_group: this.state.send_group,
      sex: "M",
    };
    if (
      validateEmail(this.state.email) &&
      ((this.state.password.length > 0 &&
        validatePassword(this.state.password)) ||
        this.state.password.length === 0) &&
      validateNull(this.state.name) &&
      validateNull(this.state.contact_person) &&
      validateNull(this.state.contact_person_phone) &&
      validateNull(this.state.phone)
    ) {
      this.props.dispatch(
        hospitalCreate(data, this.props.authInfo, this.props.history)
      );
      // if (this.state.image) {
      //   this.props.dispatch(
      //     ImageUpload(
      //       this.state.image,
      //       this.props.authInfo,
      //       this.props.history,
      //       "hos"
      //     )
      //   );
      // }
    } else {
      if (validateEmail(this.state.email) === false) {
        toast.error(translate("Invalid_email_address"));
      }
      if (this.state.password.length > 0) {
        if (validatePassword(this.state.password) === false) {
          toast.error("Password is not valid");
        }
      }
      if (validateNull(this.state.contact_person) === false) {
        toast.error(translate("missing_field") + " Contact Person");
      }
      if (validateNull(this.state.name) === false) {
        toast.error(translate("missing_field") + " Hospital Name");
      }
      if (validateNull(this.state.contact_person_phone) === false) {
        toast.error(translate("missing_field") + " Contact Person Name");
      }
      if (validateNull(this.state.phone) === false) {
        toast.error(translate("missing_field") + " Mobile Number");
      }
    }
  };

  render() {
    const { translate } = this.props;

    return (
      <>
        <div className="pb-6 pt-2 pt-md-8"></div>

        <Container className="mt--6" fluid>
          <LoadingOverlay
            active={this.props.fetchHospitalLoading}
            spinner
            text="Loading..."
          >
            <Button
              color="primary"
              style={{ float: "right", margin: "1rem" }}
              onClick={() => this.toggle()}
            >
              <i className="fas fa-plus"></i> &nbsp; {translate("Add_Hospital")}
            </Button>
            <Table striped>
              <thead>
                <tr>
                  <th>{translate("NumberH")}</th>
                  <th>{translate("Hospital_name")}</th>
                  <th>{translate("Contact_person_name")}</th>
                  <th>{translate("Contact_person_phone")}</th>
                  <th>{translate("Email_IDH")}</th>
                  <th>{translate("City")}</th>
                  <th>{translate("Action")}</th>
                </tr>
              </thead>
              <tbody>
                {this.props.hospitals
                  ? this.props.hospitals.data.map((item, i) => (
                      <tr key={i}>
                        <th scope="row">{item.id}</th>
                        <td>{item.name}</td>
                        <td>{item.contact_person}</td>
                        <td>{item.contact_person_phone}</td>
                        <td>{item.email}</td>
                        <td>{item.city}</td>
                        <td>
                          <Button
                            color="info"
                            onClick={() =>
                              this.detailsRedirect(item.id, this.state.page)
                            }
                            title="Edit"
                          >
                            <i className="fas fa-edit"></i>
                          </Button>
                          <Button
                            color="danger"
                            onClick={() => this.toggleDelete(item)}
                            title="Delete"
                          >
                            <i className="far fa-trash-alt"></i>
                          </Button>
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </Table>
          </LoadingOverlay>
          <div className="container d-flex justify-content-center">
            {this.props.hospitals != undefined ? (
              <Pagination
                count={this.props.hospitals.pagination.last_page}
                page={this.state.page}
                onChange={(event, page) => {
                  this.setState({ page: page });
                  this.props.dispatch(
                    fetchHospitals(
                      this.props.authInfo,
                      this.props.history,
                      page,
                      "id",
                      "desc"
                    )
                  );
                }}
                showFirstButton
                showLastButton
                variant="outlined"
                shape="rounded"
              />
            ) : null}
          </div>

          <Modal
            isOpen={this.state.modal}
            toggle={() => this.toggle()}
            className={this.className}
          >
            <ModalHeader toggle={() => this.toggle()}>
              {translate("Add_Hospital")}
            </ModalHeader>
            <ModalBody>
              <Form>
                <FormGroup>
                  <Label for="exampleEmail">{translate("Hospital_name")}</Label>
                  <span style={{ color: "#ff0000" }}>*</span>
                  <Input
                    type="name"
                    name="name"
                    placeholder={translate("Hospital_name")}
                    value={this.state.name}
                    onChange={(e) => this.handleChange(e)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="fullName">
                    {translate("Contact_person_name")}
                  </Label>
                  <span style={{ color: "#ff0000" }}>*</span>
                  <Input
                    type="text"
                    name="contact_person"
                    id="fullName"
                    placeholder={translate("Contact_person_name")}
                    value={this.state.contact_person}
                    onChange={(e) => this.handleChange(e)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="country">
                    {translate("Contact_person_phone")}
                  </Label>
                  <span style={{ color: "#ff0000" }}>*</span>
                  <Input
                    type="text"
                    name="contact_person_phone"
                    id="country"
                    placeholder={translate("Contact_person_phone")}
                    value={this.state.contact_person_phone}
                    onChange={(e) => this.handleChange(e)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="exampleEmail">{translate("Email_IDH")}</Label>
                  <span style={{ color: "#ff0000" }}>*</span>
                  <Input
                    type="email"
                    name="email"
                    id="exampleEmail"
                    placeholder={translate("Email_IDH")}
                    value={this.state.email}
                    onChange={(e) => this.handleChange(e)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="state">{translate("Prefecture")}</Label>
                  <Input
                    type="text"
                    name="state"
                    id="state"
                    placeholder={translate("Prefecture")}
                    value={this.state.state}
                    onChange={(e) => this.handleChange(e)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="city">{translate("City")}</Label>
                  <Input
                    type="text"
                    name="city"
                    id="city"
                    placeholder={translate("City")}
                    value={this.state.city}
                    onChange={(e) => this.handleChange(e)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="adressLine1">{translate("Address_Line_1")}</Label>
                  <Input
                    type="textarea"
                    name="address_line1"
                    id="adressLine1"
                    value={this.state.address_line1}
                    onChange={(e) => this.handleChange(e)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="adressLine2">{translate("Address_Line_2")}</Label>
                  <Input
                    type="textarea"
                    name="address_line2"
                    id="adressLine2"
                    value={this.state.address_line2}
                    onChange={(e) => this.handleChange(e)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="buildingName">{translate("Building_name")}</Label>
                  <Input
                    type="text"
                    name="building_name"
                    id="buildingName"
                    placeholder={translate("Building_Name")}
                    value={this.state.building_name}
                    onChange={(e) => this.handleChange(e)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="zipCode">{translate("Zip_code")}</Label>
                  <Input
                    type="text"
                    name="zip_code"
                    placeholder={translate("Zip_code")}
                    value={this.state.zip_code}
                    onChange={(e) => this.handleChange(e)}
                  />
                </FormGroup>
                {/* <FormGroup>
                  <Label for="zipCode">{translate("Nearest_Station")}</Label>
                  <Input
                    type="text"
                    name="nearest_station"
                    placeholder={translate("Nearest_Station")}
                    value={this.state.nearest_station}
                    onChange={(e) => this.handleChange(e)}
                  />
                </FormGroup> */}
                <FormGroup>
                  <Label for="zipCode">
                    {translate("Distance_from_Station")}
                  </Label>
                  <Input
                    type="text"
                    min="1"
                    name="dist_from_station"
                    placeholder={translate("Distance_from_Station")}
                    value={this.state.dist_from_station}
                    onChange={(e) => this.handleChange(e)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="zipCode">
                    {translate("Clinical_departmentH")}
                  </Label>

                  <Select
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    defaultValue={[]}
                    isMulti
                    options={clinical_department_value}
                    onChange={(e) => this.handleSelectChange(e)}
                    value={clinical_department_value.filter((obj) =>
                      this.state.dept.includes(obj.value)
                    )}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="Holidays">{translate("Holidays")}</Label>
                  <Input
                    type="text"
                    name="holidays"
                    placeholder={translate("Holidays")}
                    value={this.state.holidays}
                    onChange={(e) => this.handleChange(e)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="phone">{translate("Phone")}</Label>
                  <span style={{ color: "#ff0000" }}>*</span>
                  <Input
                    type="text"
                    name="phone"
                    id="phone"
                    placeholder={translate("Phone")}
                    value={this.state.phone}
                    onChange={(e) => this.handleChange(e)}
                  />
                </FormGroup>

                {/* <FormGroup>
                  <Label for="photo">Photo</Label>
                  <Input
                    type="file"
                    accept="image/png, image/jpeg"
                    name="avatar"
                    id="avatar"
                    placeholder="photo"
                    onChange={(e) => this.handleImageChange(e)}
                  />
                </FormGroup> */}

                <FormGroup>
                  <Label for="examplePassword">{translate("Password")}</Label>
                  <Input
                    type="password"
                    name="password"
                    id="examplePassword"
                    placeholder={translate("Password")}
                    value={this.state.password}
                    onChange={(e) => this.handleChange(e)}
                  />
                </FormGroup>
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={(e) => this.handleSubmit(e)}>
                {translate("Submit")}
              </Button>{" "}
              <Button color="secondary" onClick={() => this.toggle()}>
                {translate("Cancel")}
              </Button>
            </ModalFooter>
          </Modal>

          <Modal
            isOpen={this.state.deleteModal}
            toggle={() => this.toggleDelete()}
            className={this.className}
          >
            <ModalHeader>{translate("Delete_Window")}</ModalHeader>
            <ModalBody>
              {translate("Are_you_sure_You_want_to_delete_this")}
            </ModalBody>
            <ModalFooter>
              <Button color="danger" onClick={() => this.deleteAction()}>
                {translate("Delete")}
              </Button>{" "}
              <Button color="secondary" onClick={() => this.toggleDelete()}>
                {translate("Cancel")}
              </Button>
            </ModalFooter>
          </Modal>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return Object.assign({}, state.hospital, state.auth);
};

export default connect(mapStateToProps)(withRouter(withTranslate(Hospitals)));
