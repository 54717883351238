import {
  FETCH_INVOCE_LIST_START,
  FETCH_INVOCE_LIST_SUCCESS,
  FETCH_INVOCE_LIST_FAILURE,
  INVOICE_DOC_LIST_START,
  INVOICE_DOC_LIST_SUCCESS,
  INVOICE_DOC_LIST_FAILURE,
  INVOCE_PDF_START,
  INVOCE_PDF_SUCCESS,
  INVOCE_PDF_FAILURE,
  FETCH_INVOCE_LIST_LIST_SUCCESS,
} from "../constants/ActionTypes";

const initialState = {};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_INVOCE_LIST_START:
      return Object.assign({}, state, action.payload);
    case FETCH_INVOCE_LIST_SUCCESS:
      return Object.assign({}, state, action.payload);
    case FETCH_INVOCE_LIST_FAILURE:
      return Object.assign({}, state, action.payload);
    case INVOICE_DOC_LIST_START:
      return Object.assign({}, state, action.payload);
    case INVOICE_DOC_LIST_SUCCESS:
      return Object.assign({}, state, action.payload);
    case INVOICE_DOC_LIST_FAILURE:
      return Object.assign({}, state, action.payload);
    case INVOCE_PDF_START:
      return Object.assign({}, state, action.payload);
    case INVOCE_PDF_SUCCESS:
      return Object.assign({}, state, action.payload);
    case INVOCE_PDF_FAILURE:
      return Object.assign({}, state, action.payload);
    case FETCH_INVOCE_LIST_LIST_SUCCESS:
      return Object.assign({}, state, action.payload);
    default:
  }
  return state;
}
