import * as types from "../constants/ActionTypes";
import * as api from "../constants/ReUsageStaff";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { logout } from "./userActions";
toast.configure();

//line send
export const linesendStart = () => ({
  type: types.LINE_SEND_START,
  payload: {
    linesendLoading: true,
  },
});

export const linesendSuccess = (data) => ({
  type: types.LINE_SEND_SUCCESS,
  payload: {
    linesendLoading: true,
  },
});

export const linesendFailure = (error) => ({
  type: types.LINE_SEND_FAILURE,
  payload: {
    linesendLoading: true,
  },
});

export const lineSend = (data, authInfo, history) => (dispatch) => {
  dispatch(linesendStart());
  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");
  headers.append("Authorization", "Bearer " + authInfo.access_token);
  headers.append("Origin", "*");
  const requestOptions = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data),
  };

  fetch(`${api.line_send_url}`, requestOptions)
    .then((response) => {
      const statusCode = response.status;
      const data = response.json();
      return Promise.all([statusCode, data]);
    })
    .then(([statusCode, data]) => {
      if (statusCode === 200) {
        toast.success("Message Sent!");
        dispatch(linesendSuccess(data));
        setTimeout(function () {
          window.location.reload();
        }, 2000);
      } else if (statusCode === 422) {
        toast.error("Unprocessable Entity!");
        dispatch(linesendFailure(statusCode));
      } else if (statusCode === 401) {
        toast.error("unauthorized!");
        dispatch(linesendFailure(statusCode));
        dispatch(logout(history));
      } else if (data.detail) {
        toast.error(data.detail);
        dispatch(linesendFailure(data.detail));
      } else {
        dispatch(linesendFailure("Server error!"));
      }
    })
    .catch((error) => {
      toast.error("something went wrong, Try later!");
      dispatch(linesendFailure(error));
      dispatch(logout(history));
    });
};
