import React from "react";
import { Table } from "reactstrap";
import {
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslate } from "react-redux-multilingual";
import {
  fetchDoctors,
  doctorCreate,
  doctorUpdate,
} from "../../actions/doctorActions";
import { ImageUpload } from "../../actions/userActions";
import Pagination from "@material-ui/lab/Pagination";
import {
  validateEmail,
  validateNull,
  validatePassword,
} from "../../services/validation";
import { toast } from "react-toastify";
import LoadingOverlay from "react-loading-overlay";
import { clinical_department_value } from "../../constants/clinicalDepartment";
import Select from "react-select";
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();
class Doctors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      deleteModal: false,
      deleteId: "",
      password: "",
      page: 1,
      graduation_date_value: null,
      dept: [],
      email: "",
      // image: null,
    };
  }

  componentWillMount() {
    if (this.props.location.page != undefined) {
      this.setState({ page: this.props.location.page });
      this.props.dispatch(
        fetchDoctors(
          this.props.authInfo,
          this.props.history,
          this.props.location.page,
          3,
          "desc",
          "id"
        )
      );
    } else {
      this.props.dispatch(
        fetchDoctors(
          this.props.authInfo,
          this.props.history,
          1,
          3,
          "desc",
          "id"
        )
      );
    }
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });

    if (name === "date_of_birth") {
      const parts = value.match(/(\d+)/g);
      const dob = new Date(parts[0], parts[1] - 1, parts[2]); // months are 0-based
      var ageDifMs = Date.now() - dob.getTime();
      var ageDate = new Date(ageDifMs); // miliseconds from epoch
      this.setState({ age: Math.abs(ageDate.getUTCFullYear() - 1970) });
    }
  }

  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };
  toggleDelete = (id) => {
    if (this.state.deleteModal === false) {
      this.setState({ deleteId: id });
    }
    this.setState({ deleteModal: !this.state.deleteModal });
  };
  deleteAction = () => {
    this.props.dispatch(
      doctorUpdate(
        { is_active: 0 },
        this.props.authInfo,
        this.props.history,
        this.state.deleteId.id
      )
    );
    // after delete
    this.setState({ deleteId: "" });
    setTimeout(function () {
      window.location.reload();
    }, 2000);
  };
  detailsRedirect = (data, p) => {
    this.props.history.push({
      pathname: "/admin/doctor-details",
      state: data,
      page: p,
    });
  };
  handleSelectChange = (e) => {
    this.setState({
      dept: Array.isArray(e) ? e.map((x) => x.value) : [],
    });
  };
  // handleImageChange = (event) => {
  //   if (event.target.files && event.target.files.length > 0) {
  //     this.setState({
  //       image: event.target.files[0],
  //     });
  //   } else {
  //     this.setState({
  //       image: null,
  //     });
  //   }
  // };
  handleSubmit = (e) => {
    const { translate } = this.props;
    e.preventDefault();
    let data = {
      first_name: this.state.first_name,
      first_name_yomigana: this.state.first_name_yomigana,
      last_name: this.state.last_name,
      last_name_yomigana: this.state.last_name_yomigana,
      age: this.state.age,
      university: this.state.university,
      clinical_department: this.state.dept.join(),
      date_of_birth: this.state.date_of_birth,
      sex: this.state.sex,
      email: this.state.email,
      country: this.state.country,
      state: this.state.state,
      city: this.state.city,
      address_line1: this.state.address_line1,
      address_line2: this.state.address_line2,
      building_name: this.state.building_name,
      zip_code: this.state.zip_code,
      graduation_date: this.state.graduation_date,
      license_approval_date: this.state.license_approval_date,
      phone: this.state.phone,
      password: this.state.password,
    };
    if (
      validateEmail(this.state.email) &&
      ((this.state.password.length > 0 &&
        validatePassword(this.state.password)) ||
        this.state.password.length === 0) &&
      validateNull(this.state.last_name) &&
      validateNull(this.state.first_name) &&
      validateNull(this.state.phone)
    ) {
      this.props.dispatch(
        doctorCreate(data, this.props.authInfo, this.props.history)
      );
      // image upload
      // if (this.state.image) {
      //   this.props.dispatch(
      //     ImageUpload(
      //       this.state.image,
      //       this.props.authInfo,
      //       this.props.history,
      //       "doc"
      //     )
      //   );
      // }
    } else {
      if (validateEmail(this.state.email) === false) {
        toast.error(translate("Invalid_email_address"));
      }
      if (this.state.password.length > 0) {
        if (validatePassword(this.state.password) === false) {
          toast.error("Password is not valid");
        }
      }
      if (validateNull(this.state.last_name) === false) {
        toast.error(translate("missing_field") + " Last Name");
      }
      if (validateNull(this.state.first_name) === false) {
        toast.error(translate("missing_field") + " First Name");
      }
      if (validateNull(this.state.phone) === false) {
        toast.error(translate("missing_field") + " Mobile Number");
      }
    }
  };
  render() {
    const { translate } = this.props;
    return (
      <>
        <div className="pb-6 pt-2 pt-md-8"></div>

        <Container className="mt--6" fluid>
          <LoadingOverlay
            active={this.props.fetchDoctorLoading}
            spinner
            text="Loading..."
          >
            <Button
              color="primary"
              style={{ float: "right", margin: "1rem" }}
              onClick={() => this.toggle()}
            >
              <i className="fas fa-plus"></i> &nbsp; {translate("Add_Doctor")}
            </Button>
            <Table striped>
              <thead>
                <tr>
                  <th>{translate("NumberD")}</th>
                  <th>{translate("last_name")}</th>
                  <th>{translate("first_name")}</th>
                  <th>Line ID</th>
                  <th>{translate("Mobile_number")}</th>
                  <th>{translate("Actions")}</th>
                </tr>
              </thead>
              <tbody>
                {this.props.doctors
                  ? this.props.doctors.data.map((item, i) => (
                      <tr key={i}>
                        <td>{item.id}</td>
                        <td>{item.last_name}</td>
                        <td>{item.first_name}</td>
                        <td>{item.line_number}</td>
                        <td>{item.phone}</td>
                        <td>
                          <Button
                            color="info"
                            onClick={() =>
                              this.detailsRedirect(item, this.state.page)
                            }
                            title={translate("edit")}
                          >
                            <i className="fas fa-edit"></i>
                          </Button>
                          <Button
                            color="danger"
                            onClick={() => this.toggleDelete(item)}
                            title={translate("Delete")}
                          >
                            <i className="far fa-trash-alt"></i>
                          </Button>
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </Table>
          </LoadingOverlay>
          <div className="container d-flex justify-content-center">
            {this.props.doctors != undefined ? (
              <Pagination
                count={this.props.doctors.pagination.last_page}
                page={this.state.page}
                onChange={(event, page) => {
                  this.setState({ page: page });
                  this.props.dispatch(
                    fetchDoctors(
                      this.props.authInfo,
                      this.props.history,
                      page,
                      "3",
                      "desc",
                      "id"
                    )
                  );
                }}
                showFirstButton
                showLastButton
                variant="outlined"
                shape="rounded"
              />
            ) : null}
          </div>

          <Modal
            isOpen={this.state.modal}
            toggle={() => this.toggle()}
            className={this.className}
          >
            <ModalHeader toggle={() => this.toggle()}>
              {translate("Add_Doctor")}
            </ModalHeader>
            <ModalBody>
              <Form>
                <FormGroup>
                  <Label for="exampleEmail">{translate("Email_IDD")}</Label>
                  <span style={{ color: "#ff0000" }}>*</span>
                  <Input
                    type="email"
                    name="email"
                    id="exampleEmail"
                    placeholder={translate("Email_IDD")}
                    value={this.state.email}
                    onChange={(e) => this.handleChange(e)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="last_name">{translate("Last_name_doctor")}</Label>
                  <span style={{ color: "#ff0000" }}>*</span>
                  <Input
                    type="text"
                    name="last_name"
                    placeholder={translate("last_name")}
                    value={this.state.last_name}
                    onChange={(e) => this.handleChange(e)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="last_name_yomigana">
                    {translate("Last_name_katakana")}
                  </Label>
                  <Input
                    type="text"
                    name="last_name_yomigana"
                    id="last_name_yomigana"
                    value={this.state.last_name_yomigana}
                    placeholder={translate("last_name_y")}
                    onChange={(e) => this.handleChange(e)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="first_name">
                    {translate("First_name_doctor")}
                  </Label>
                  <span style={{ color: "#ff0000" }}>*</span>
                  <Input
                    type="text"
                    name="first_name"
                    id="first_name"
                    placeholder={translate("first_name")}
                    value={this.state.first_name}
                    onChange={(e) => this.handleChange(e)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="first_name_yomigana">
                    {translate("First_name_katakana")}
                  </Label>
                  <Input
                    type="text"
                    name="first_name_yomigana"
                    id="first_name_yomigana"
                    placeholder={translate("first_name_y")}
                    value={this.state.first_name_yomigana}
                    onChange={(e) => this.handleChange(e)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="state">{translate("Prefecture")}</Label>
                  <Input
                    type="text"
                    name="state"
                    value={this.state.state}
                    placeholder={translate("Prefecture")}
                    onChange={(e) => this.handleChange(e)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="city">{translate("City")}</Label>
                  <Input
                    type="text"
                    name="city"
                    id="city"
                    value={this.state.city}
                    placeholder={translate("City")}
                    onChange={(e) => this.handleChange(e)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="adressLine1">{translate("Address_Line_1")}</Label>
                  <Input
                    type="textarea"
                    name="address_line1"
                    id="adressLine1"
                    value={this.state.address_line1}
                    onChange={(e) => this.handleChange(e)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="adressLine2">{translate("Address_Line_2")}</Label>
                  <Input
                    type="textarea"
                    name="address_line2"
                    id="adressLine2"
                    value={this.state.address_line2}
                    onChange={(e) => this.handleChange(e)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="buildingName">{translate("Building_name")}</Label>
                  <Input
                    type="text"
                    name="building_name"
                    id="buildingName"
                    value={this.state.building_name}
                    placeholder={translate("Building_name")}
                    onChange={(e) => this.handleChange(e)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="zipCode">{translate("Zip_code")}</Label>
                  <Input
                    type="text"
                    name="zip_code"
                    id="zipCode"
                    value={this.state.zip_code}
                    placeholder={translate("Zip_code")}
                    onChange={(e) => this.handleChange(e)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="phone">{translate("Mobile_number")}</Label>
                  <span style={{ color: "#ff0000" }}>*</span>
                  <Input
                    type="text"
                    name="phone"
                    id="phone"
                    value={this.state.phone}
                    placeholder={translate("Mobile_number")}
                    onChange={(e) => this.handleChange(e)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="dateOfBirth">{translate("dob")}</Label>
                  <Input
                    type="date"
                    name="date_of_birth"
                    id="dateOfBirth"
                    max="2999-12-31"
                    placeholder={translate("dob")}
                    value={this.state.date_of_birth}
                    onChange={(e) => this.handleChange(e)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="age">{translate("Age")}</Label>
                  <Input
                    readOnly
                    type="text"
                    name="age"
                    id="age"
                    placeholder={translate("Age")}
                    value={this.state.age}
                    onChange={(e) => this.handleChange(e)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="university">{translate("University")}</Label>
                  <Input
                    type="text"
                    name="university"
                    id="university"
                    placeholder={translate("University")}
                    value={this.state.university}
                    onChange={(e) => this.handleChange(e)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="graduation_date">
                    {translate("Graduation_date")}
                  </Label>
                  <Input
                    type="month"
                    name="graduation_date"
                    id="graduation_date"
                    value={this.state.graduation_date_value}
                    placeholder={translate("Graduation_date")}
                    max="9999-12"
                    onChange={(e) => {
                      e.preventDefault();
                      this.setState({
                        graduation_date: e.target.value + "-01",
                      });
                      this.setState({ graduation_date_value: e.target.value });
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="licenseApprovalDate">
                    {translate("License_approval_date")}
                  </Label>
                  <Input
                    type="date"
                    name="license_approval_date"
                    id="license_approval_date"
                    value={this.state.license_approval_date}
                    placeholder={translate("License_approval_date")}
                    max="2999-12-31"
                    onChange={(e) => this.handleChange(e)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="clinical_department">
                    {translate("Clinical_departmentD")}
                  </Label>
                  <Select
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    defaultValue={[]}
                    isMulti
                    options={clinical_department_value}
                    onChange={(e) => this.handleSelectChange(e)}
                    value={clinical_department_value.filter((obj) =>
                      this.state.dept.includes(obj.value)
                    )}
                  />
                </FormGroup>
                {/* <FormGroup>
                  <Label for="photo">Photo</Label>
                  <Input
                    type="file"
                    accept="image/png, image/jpeg"
                    name="avatar"
                    id="avatar"
                    placeholder="photo"
                    onChange={(e) => this.handleImageChange(e)}
                  />
                </FormGroup> */}
                <FormGroup>
                  <Label for="examplePassword">{translate("Password")}</Label>
                  <Input
                    type="password"
                    name="password"
                    id="examplePassword"
                    placeholder={translate("Password")}
                    value={this.state.password}
                    onChange={(e) => this.handleChange(e)}
                  />
                </FormGroup>
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={(e) => this.handleSubmit(e)}>
                {translate("Submit")}
              </Button>{" "}
              <Button color="secondary" onClick={() => this.toggle()}>
                {translate("Cancel")}
              </Button>
            </ModalFooter>
          </Modal>

          <Modal
            isOpen={this.state.deleteModal}
            toggle={() => this.toggleDelete()}
            className={this.className}
          >
            <ModalHeader>{translate("Delete_Window")}</ModalHeader>
            <ModalBody>
              {translate("Are_you_sure_You_want_to_delete_this")}
            </ModalBody>
            <ModalFooter>
              <Button color="danger" onClick={() => this.deleteAction()}>
                {translate("Delete")}
              </Button>{" "}
              <Button color="secondary" onClick={() => this.toggleDelete()}>
                {translate("Cancel")}
              </Button>
            </ModalFooter>
          </Modal>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return Object.assign({}, state.doctor, state.auth);
};

export default connect(mapStateToProps)(withRouter(withTranslate(Doctors)));
