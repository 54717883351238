
export let base_url = "https://dd-api-fast.herokuapp.com/api/v1";
if(process.env.REACT_APP_IS_PROD){
  // console.log('prod')
  base_url = "https://api.doctors-time.com/api/v1";
}
else if(process.env.REACT_APP_IS_DEV){
  // console.log('dev')
  base_url = "https://dev-api.doctors-time.com/api/v1";
}
else{
  console.log('na')
}

export const login_url = base_url + "/users/login";
export const create_hospital_url = base_url + "/hospital/create";
export const update_hospital_url = base_url + "/hospital/modify/";
export const details_hospital_url = base_url + "/hospital/us";
export const get_hospitals_url = base_url + "/hospital/all";
export const user_info_url = base_url + "/users/me";
export const create_doctor_url = base_url + "/users/create";
export const update_doctor_url = base_url + "/users/modify/";
export const get_doctors_url = base_url + "/users/users";
export const create_group_url = base_url + "/groups/create";
export const update_group_url = base_url + "/groups/modify/";
export const delete_group_url = base_url + "/groups/delete/";
export const get_groups_url = base_url + "/groups/groups";
export const update_group_members_url = base_url + "/groups/members";
export const fetch_group_members_url = base_url + "/groups/list/members/";
export const fetch_members_not_in_group__url =
  base_url + "/groups/except/members/";
export const line_send_url = base_url + "/line/send/group";
export const invoice_list_url = base_url + "/attendance/admin/hospital_list";
export const invoice_doc_list_url = base_url + "/users/modify/";
export const invoice_pdf_url = base_url + "/attendance/admin/invoice/create";
export const invoice_att_list_url = base_url + "/attendance/admin/attendances";
export const hos_image_upload_url = base_url + "/hospital/upload/profile-image";
export const doc_image_upload_url = base_url + "/users/upload/profile-image";
export const doc_get_single = base_url + "/users/";
export const get_single_hospital=base_url+"/hospital/"
