import { combineReducers } from "redux";
import { IntlReducer as Intl } from "react-redux-multilingual";
import authReducer from "./authReducer";
import hospitalReducer from "./hospitalsReducer";
import doctorsReducer from "./doctorsReducer";
import groupsReducer from "./groupsReducer";
import invoiceReducer from "./invoiceReducer";
import lineReducer from "./lineReducer";
const rootReducer = combineReducers({
  auth: authReducer,
  hospital: hospitalReducer,
  doctor: doctorsReducer,
  group: groupsReducer,
  invoice: invoiceReducer,
  line: lineReducer,
  Intl,
});

export default rootReducer;
