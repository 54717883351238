import React from "react";
import { Table } from "reactstrap";
import { Container, Button, Col, Row } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslate } from "react-redux-multilingual";
import { fetchInvoices } from "../../actions/invoiceActions";
// import Pagination from "@material-ui/lab/Pagination";

class DoctorsListInvoice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentWillMount() {
    let d = new Date();
    if (
      this.props.location.year != undefined ||
      this.props.location.month != undefined
    ) {
      this.props.dispatch(
        fetchInvoices(
          this.props.authInfo,
          this.props.history,
          this.props.location.year,
          this.props.location.month,
          this.props.location.state,
          "list"
        )
      );
    } else {
      this.props.history.push({
        pathname: "/admin/invoice",
      });
    }
  }

  render() {
    const { translate } = this.props;
    return (
      <>
        <div className="pb-6 pt-2 pt-md-8"></div>

        <Container className="mt--6" fluid>
          <Row className="align-items-center">
            <Col className="order-xl-1" xl="12">
              <Col xs="8"></Col>
              <Col xs="4">
                <Button
                  color="info"
                  onClick={(e) => this.props.history.push("/admin/invoice")}
                  size="sm"
                >
                  {translate("Return")}
                </Button>
              </Col>
            </Col>
          </Row>

          <br />
          <Table striped>
            <thead>
              <tr>
                <th> {translate("Job_Code")}</th>
                <th> {translate("Hospital_name")}</th>
                <th> {translate("doctor_name")}</th>
                <th> {translate("Start_work_date")}</th>
                <th> {translate("Work_start")}</th>
                <th> {translate("Work_finish")}</th>
                {/* <th> {translate("total_work_hours")}</th> */}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {this.props.invoicesList
                ? this.props.invoicesList.map((item, i) => (
                    <tr>
                      <th scope="row">{item.job_id}</th>
                      <th>{item.hospital_name}</th>
                      <th>
                        {item.doctor_last_name}&nbsp; {item.doctor_first_name}
                      </th>
                      <td>{item.start_date}</td>
                      <td>{item.start_time}</td>
                      <td>{item.end_time}</td>
                      {/* <td>{item.hour_worked}</td> */}
                      <td>
                        {
                          item.is_approved == 1
                            ? // <Button disabled color="info">
                              translate("Approve")
                            : // </Button>
                              // <Button disabled color="white">
                              translate("Not_Approve")
                          // </Button>
                        }
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </Table>
          {/* <div className="container d-flex justify-content-center">
            {this.props.groups != undefined ? (
              <Pagination
                count={this.props.groups.pagination.last_page}
                onChange={(event, page) => {
                  this.props.dispatch(
                    fetchGroups(
                      this.props.authInfo,
                      this.props.history,
                      page,
                      "group_name",
                      "asc"
                    )
                  );
                }}
                showFirstButton
                showLastButton
                variant="outlined"
                shape="rounded"
              />
            ) : null}
          </div> */}
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return Object.assign({}, state.invoice, state.auth);
};

export default connect(mapStateToProps)(
  withRouter(withTranslate(DoctorsListInvoice))
);
