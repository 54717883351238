import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  UncontrolledCollapse,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  DropdownMenu,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { withTranslate } from "react-redux-multilingual";
import { IntlActions } from "react-redux-multilingual";
import store from "../../store/index";

const AdminNavbar = (props) => {
  const changeLanguage = (lang) => {
    store.dispatch(IntlActions.setLocale(lang));
  };
  const { translate } = props;
  return (
    <>
      <Navbar className="navbar-top navbar-horizontal navbar-dark" expand="md">
        <Container className="px-4">
          <button className="navbar-toggler" id="navbar-collapse-main">
            <span className="navbar-toggler-icon" />
          </button>
          <UncontrolledCollapse navbar toggler="#navbar-collapse-main">
            <div className="navbar-collapse-header d-md-none">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <Link to="/">
                    <img
                      alt="..."
                      src={
                        require("../../assets/img/brand/argon-react.png")
                          .default
                      }
                    />
                  </Link>
                </Col>
                <Col className="collapse-close" xs="6">
                  <button className="navbar-toggler" id="navbar-collapse-main">
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            <Nav className="ml-auto" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <div className="ml-2 d-none d-lg-block">
                    <span>
                      <i className="ni ni-settings" />
                    </span>
                    <span
                      className="mb-0 text-white text-sm font-weight-bold "
                      style={{ color: "black" }}
                    >
                      Translate
                    </span>
                  </div>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">Welcome!</h6>
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      changeLanguage("en");
                    }}
                  >
                    <i className="ni ni-single-02" />
                    <span>{translate("English")}</span>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem
                    onClick={() => {
                      changeLanguage("jp");
                    }}
                  >
                    <i className="ni ni-settings-gear-65" />
                    <span>{translate("Japanese")}</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </UncontrolledCollapse>
        </Container>
      </Navbar>
      <div className="pb-10 pt-md-7"></div>
      <div className="mt-2"></div>
    </>
  );
};

export default withRouter(withTranslate(AdminNavbar));
