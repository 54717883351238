import {
  FETCH_HOSPITALS_START,
  FETCH_HOSPITALS_SUCCESS,
  FETCH_HOSPITALS_FAILURE,
  US_HOSPITALS_START,
  US_HOSPITALS_SUCCESS,
  US_HOSPITALS_FAILURE,
  UPDATE_HOSPITALS_START,
  UPDATE_HOSPITALS_SUCCESS,
  UPDATE_HOSPITALS_FAILURE,
  CREATE_HOSPITALS_START,
  CREATE_HOSPITALS_SUCCESS,
  CREATE_HOSPITALS_FAILURE,
  FETCH_SINGLE_HOSPITAL_START,
  FETCH_SINGLE_HOSPITAL_SUCCESS,
  FETCH_SINGLE_HOSPITAL_FAILURE
} from "../constants/ActionTypes";

const initialState = {};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_HOSPITALS_START:
      return Object.assign({}, state, action.payload);
    case FETCH_HOSPITALS_SUCCESS:
      return Object.assign({}, state, action.payload);
    case FETCH_HOSPITALS_FAILURE:
      return Object.assign({}, state, action.payload);
    case US_HOSPITALS_START:
      return Object.assign({}, state, action.payload);
    case US_HOSPITALS_SUCCESS:
      return Object.assign({}, state, action.payload);
    case US_HOSPITALS_FAILURE:
      return Object.assign({}, state, action.payload);
    case UPDATE_HOSPITALS_START:
      return Object.assign({}, state, action.payload);
    case UPDATE_HOSPITALS_SUCCESS:
      return Object.assign({}, state, action.payload);
    case UPDATE_HOSPITALS_FAILURE:
      return Object.assign({}, state, action.payload);
    case CREATE_HOSPITALS_START:
      return Object.assign({}, state, action.payload);
    case CREATE_HOSPITALS_SUCCESS:
      return Object.assign({}, state, action.payload);
    case CREATE_HOSPITALS_FAILURE:
      return Object.assign({}, state, action.payload);

    case FETCH_SINGLE_HOSPITAL_START:
      return Object.assign({}, state, action.payload);
    case FETCH_SINGLE_HOSPITAL_SUCCESS:
      return Object.assign({}, state, action.payload);
    case FETCH_SINGLE_HOSPITAL_FAILURE:
      return Object.assign({}, state, action.payload);
    default:
  }
  return state;
}
