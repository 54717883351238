// import Index from "views/Index.js";
import Profile from "views/examples/Profile.js";
// import Maps from "views/examples/Maps.js";
import Register from "views/examples/Register.js";
import Login from "views/examples/Login.js";
// import Tables from "views/examples/Tables.js";
// import Icons from "views/examples/Icons.js";
import Doctors from "views/Doctor";
import Hospitals from "views/Hospitals";
import DoctorsGroup from "views/Doctors Group";
import LineNotification from "views/Line Notification";
import Invoice from "views/Invoice";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Profile,
    layout: "/admin",
  },
  {
    path: "/Doctor",
    name: "Doctor",
    icon: "ni ni-planet text-blue",
    component: Doctors,
    layout: "/admin",
  },
  // {
  //   path: "/icons",
  //   name: "Doctors",
  //   icon: "ni ni-planet text-blue",
  //   component: Icons,
  //   layout: "/admin",
  // },
  {
    path: "/maps",
    name: "Hospitals",
    icon: "ni ni-pin-3 text-orange",
    component: Hospitals,
    layout: "/admin",
  },
  {
    path: "/user-profile",
    name: "Doctors Group",
    icon: "ni ni-single-02 text-yellow",
    component: DoctorsGroup,
    layout: "/admin",
  },
  {
    path: "/line-notification",
    name: "Line Notification",
    icon: "ni ni-single-02 text-yellow",
    component: LineNotification,
    layout: "/admin",
  },
  {
    path: "/invoice",
    name: "Invoice",
    icon: "ni ni-single-02 text-yellow",
    component: Invoice,
    layout: "/admin",
  },
  // {
  //   path: "/tables",
  //   name: "Tables",
  //   icon: "ni ni-bullet-list-67 text-red",
  //   component: Tables,
  //   layout: "/admin",
  // },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/admin",
  },
  {
    path: "/login",
    name: "Logout",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
  },
];
export default routes;
