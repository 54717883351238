import React from "react";
import {
  Container,
  Button,
  FormGroup,
  Label,
  Input,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslate } from "react-redux-multilingual";
import { lineSend } from "../../actions/lineActions";
import { fetchDoctors } from "../../actions/doctorActions";
import { fetchGroups } from "../../actions/groupActions";
import { toast } from "react-toastify";

const animatedComponents = makeAnimated();

class LineNotification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      doctors: null,
      groups: null,
      optionGrp: [],
      optionDoc: [],
      message: "",
      selectedGrp: [],
      selectedDoc: [],
    };
  }
  componentWillMount() {
    this.props.dispatch(
      fetchDoctors(this.props.authInfo, this.props.history, 0, 3, "asc", "id")
    );
    this.props.dispatch(
      fetchGroups(
        this.props.authInfo,
        this.props.history,
        0,
        "group_name",
        "asc"
      )
    );
  }
  componentWillReceiveProps(newProps) {
    if (this.state.doctors != newProps.doctors) {
      this.setState({ doctors: newProps.doctors });
      // after api fix please uncomment this
      for (let i = 0; i < newProps.doctors.data.length; i++) {
        this.state.optionDoc.push({
          label:
            newProps.doctors.data[i].last_name +
            " " +
            newProps.doctors.data[i].first_name,

          value: newProps.doctors.data[i].id,
        });
      }
    }
    if (this.state.groups != newProps.groups) {
      this.setState({ groups: newProps.groups });
      for (let i = 0; i < newProps.groups.data.length; i++) {
        this.state.optionGrp.push({
          label: newProps.groups.data[i].group_name,
          value: newProps.groups.data[i].id,
        });
      }
    }
  }
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }
  handleSelectChange = (e) => {
    this.setState({
      selectedGrp: Array.isArray(e) ? e.map((x) => x.value) : [],
    });
  };
  handleDocSelectChange = (e) => {
    this.setState({
      selectedDoc: Array.isArray(e) ? e.map((x) => x.value) : [],
    });
  };
  handleSubmit(e) {
    if (
      this.state.selectedGrp.length == 0 &&
      this.state.selectedDoc.length == 0
    ) {
      toast.warn("select at least one group or doctor!");
    } else {
      let data = {
        user_id: this.state.selectedDoc,
        groups: this.state.selectedGrp,
        message: this.state.message,
      };
      this.props.dispatch(
        lineSend(data, this.props.authInfo, this.props.history)
      );
    }
  }
  render() {
    const { translate } = this.props;
    return (
      <>
        {/* <Header /> */}
        <div className="pb-6 pt-2 pt-md-8"></div>

        <Container className="mt--7" fluid>
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">{translate("Line_Notification")}</h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Label for="exampleText">
                {translate("Select_Doctors_Group")}
              </Label>
              <Select
                closeMenuOnSelect={false}
                components={animatedComponents}
                defaultValue={[]}
                isMulti
                options={this.state.optionGrp}
                onChange={(e) => this.handleSelectChange(e)}
                value={this.state.optionGrp.filter((obj) =>
                  this.state.selectedGrp.includes(obj.value)
                )}
              />
              <br />
              <Label for="exampleText">{translate("Select_Doctors")} </Label>
              <Select
                closeMenuOnSelect={false}
                isMulti
                options={this.state.optionDoc}
                onChange={(e) => this.handleDocSelectChange(e)}
                // value={this.state.optionDoc.filter((obj) =>
                //   this.state.selectedDoc.includes(obj.value)
                // )}
              />
              <br />
              <FormGroup>
                <Label for="exampleText">{translate("Message")}</Label>
                <Input
                  type="textarea"
                  name="message"
                  onChange={(e) => this.handleChange(e)}
                  id="exampleText"
                  value={this.state.message}
                />
              </FormGroup>
              <Button color="primary" onClick={(e) => this.handleSubmit(e)}>
                {translate("Send_Message")}
              </Button>{" "}
            </CardBody>
          </Card>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return Object.assign({}, state.doctor, state.auth, state.group, state.line);
};
export default connect(mapStateToProps)(
  withRouter(withTranslate(LineNotification))
);
