import * as types from "../constants/ActionTypes";
import * as api from "../constants/ReUsageStaff";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { logout } from "./userActions";
toast.configure();

//fetch invoices
export const fetchinvoicesStart = () => ({
  type: types.FETCH_INVOCE_LIST_START,
  payload: {
    fetchinvoicesLoading: true,
  },
});

export const fetchinvoicesSuccess = (data) => ({
  type: types.FETCH_INVOCE_LIST_SUCCESS,
  payload: {
    invoices: data,
    fetchinvoicesLoading: false,
  },
});
export const fetchinvoicesListSuccess = (data) => ({
  type: types.FETCH_INVOCE_LIST_LIST_SUCCESS,
  payload: {
    invoicesList: data,
    fetchinvoicesLoading: false,
  },
});

export const fetchinvoicesFailure = (error) => ({
  type: types.FETCH_INVOCE_LIST_FAILURE,
  payload: {
    fetchinvoicesLoading: false,
  },
});

export const fetchInvoices = (authInfo, history, year, month, page, type) => (
  dispatch
) => {
  dispatch(fetchinvoicesStart());
  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");
  headers.append("Authorization", "Bearer " + authInfo.access_token);
  headers.append("Origin", "*");
  const requestOptions = {
    method: "GET",
    headers: headers,
  };

  let url = "";
  if (type == "") {
    url = `${api.invoice_list_url}?year=${year}&month=${month}&page=${page}`;
  }
  if (type == "list") {
    url = `${api.invoice_att_list_url}?year=${year}&month=${month}&hospital_id=${page}`;
  }
  fetch(url, requestOptions)
    .then((response) => {
      const statusCode = response.status;
      const data = response.json();
      return Promise.all([statusCode, data]);
    })
    .then(([statusCode, data]) => {
      if (statusCode === 200) {
        if (type == "list") {
          dispatch(fetchinvoicesListSuccess(data));
        } else {
          dispatch(fetchinvoicesSuccess(data));
        }
      } else if (statusCode === 401) {
        toast.error("unauthorized!");
        dispatch(fetchinvoicesFailure(statusCode));
        dispatch(logout(history));
      } else if (data.detail) {
        toast.error(data.detail);
        dispatch(fetchinvoicesFailure(data.detail));
      } else {
        dispatch(fetchinvoicesFailure("Server error!"));
      }
    })
    .catch((error) => {
      toast.error("something went wrong, Try later!");
      dispatch(fetchinvoicesFailure(error));
    });
};

//invoice doc list
export const doclistStart = () => ({
  type: types.INVOICE_DOC_LIST_START,
  payload: {
    doclistLoading: true,
  },
});

export const doclistSuccess = (data) => ({
  type: types.INVOICE_DOC_LIST_SUCCESS,
  payload: {
    doclistInvoice: data,
    doclistLoading: true,
  },
});

export const doclistFailure = (error) => ({
  type: types.INVOICE_DOC_LIST_FAILURE,
  payload: {
    doclistLoading: true,
  },
});

export const docListInvoice = (authInfo, history, id) => (dispatch) => {
  dispatch(doclistStart());
  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");
  headers.append("Authorization", "Bearer " + authInfo.access_token);
  headers.append("Origin", "*");
  const requestOptions = {
    method: "GET",
    headers: headers,
  };

  fetch(`${api.invoice_doc_list_url}${id}`, requestOptions)
    .then((response) => {
      const statusCode = response.status;
      const data = response.json();
      return Promise.all([statusCode, data]);
    })
    .then(([statusCode, data]) => {
      if (statusCode === 200) {
        dispatch(doclistSuccess(data));
      } else if (statusCode === 401) {
        toast.error("unauthorized!");
        dispatch(doclistFailure(statusCode));
        dispatch(logout(history));
      } else if (data.detail) {
        toast.error(data.detail);
        dispatch(doclistFailure(data.detail));
      } else {
        dispatch(doclistFailure("Server error!"));
      }
    })
    .catch((error) => {
      toast.error("something went wrong, Try later!");
      dispatch(doclistFailure(error));
      dispatch(logout(history));
    });
};

//invoice pdf
export const pdfStart = () => ({
  type: types.INVOCE_PDF_START,
  payload: {
    pdfLoading: true,
  },
});

export const pdfSuccess = (data) => ({
  type: types.INVOCE_PDF_SUCCESS,
  payload: {
    pdfInvoice: data,
    pdfLoading: true,
  },
});

export const pdfFailure = (error) => ({
  type: types.INVOCE_PDF_FAILURE,
  payload: {
    pdfLoading: true,
  },
});

export const pdfInvoice = (authInfo, history, year, month, id) => (
  dispatch
) => {
  dispatch(pdfStart());
  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");
  headers.append("Authorization", "Bearer " + authInfo.access_token);
  headers.append("Origin", "*");
  const requestOptions = {
    method: "GET",
    headers: headers,
  };

  fetch(
    `${api.invoice_pdf_url}?year=${year}&month=${month}&hospital_id=${id}`,
    requestOptions
  )
    .then((response) => {
      const statusCode = response.status;
      const data = response.json();
      return Promise.all([statusCode, data]);
    })
    .then(([statusCode, data]) => {
      if (statusCode === 200) {
        dispatch(pdfSuccess(data));
      } else if (statusCode === 401) {
        toast.error("unauthorized!");
        dispatch(pdfFailure(statusCode));
        dispatch(logout(history));
      } else if (data.detail) {
        toast.error(data.detail);
        dispatch(pdfFailure(data.detail));
      } else {
        dispatch(pdfFailure("Server error!"));
      }
    })
    .catch((error) => {
      toast.error("something went wrong, Try later!");
      dispatch(pdfFailure(error));
    });
};
