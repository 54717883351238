import {
  FETCH_GROUPS_START,
  FETCH_GROUPS_SUCCESS,
  FETCH_GROUPS_FAILURE,
  UPDATE_GROUPS_START,
  UPDATE_GROUPS_SUCCESS,
  UPDATE_GROUPS_FAILURE,
  CREATE_GROUPS_START,
  CREATE_GROUPS_SUCCESS,
  CREATE_GROUPS_FAILURE,
  DELETE_GROUPS_START,
  DELETE_GROUPS_SUCCESS,
  DELETE_GROUPS_FAILURE,
  FETCH_GROUPS_MEMBERS_START,
  FETCH_GROUPS_MEMBERS_SUCCESS,
  FETCH_GROUPS_MEMBERS_FAILURE,
  UPDATE_GROUPS_MEMBERS_START,
  UPDATE_GROUPS_MEMBERS_SUCCESS,
  UPDATE_GROUPS_MEMBERS_FAILURE,
  FETCH_NOT_GROUPS_MEMBERS_START,
  FETCH_NOT_GROUPS_MEMBERS_SUCCESS,
  FETCH_NOT_GROUPS_MEMBERS_FAILURE,
} from "../constants/ActionTypes";

const initialState = {};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_GROUPS_START:
      return Object.assign({}, state, action.payload);
    case FETCH_GROUPS_SUCCESS:
      return Object.assign({}, state, action.payload);
    case FETCH_GROUPS_FAILURE:
      return Object.assign({}, state, action.payload);
    case DELETE_GROUPS_START:
      return Object.assign({}, state, action.payload);
    case DELETE_GROUPS_SUCCESS:
      return Object.assign({}, state, action.payload);
    case DELETE_GROUPS_FAILURE:
      return Object.assign({}, state, action.payload);
    case UPDATE_GROUPS_START:
      return Object.assign({}, state, action.payload);
    case UPDATE_GROUPS_SUCCESS:
      return Object.assign({}, state, action.payload);
    case UPDATE_GROUPS_FAILURE:
      return Object.assign({}, state, action.payload);
    case CREATE_GROUPS_START:
      return Object.assign({}, state, action.payload);
    case CREATE_GROUPS_SUCCESS:
      return Object.assign({}, state, action.payload);
    case CREATE_GROUPS_FAILURE:
      return Object.assign({}, state, action.payload);
    case FETCH_GROUPS_MEMBERS_START:
      return Object.assign({}, state, action.payload);
    case FETCH_GROUPS_MEMBERS_SUCCESS:
      return Object.assign({}, state, action.payload);
    case FETCH_GROUPS_MEMBERS_FAILURE:
      return Object.assign({}, state, action.payload);
    case UPDATE_GROUPS_MEMBERS_START:
      return Object.assign({}, state, action.payload);
    case UPDATE_GROUPS_MEMBERS_SUCCESS:
      return Object.assign({}, state, action.payload);
    case UPDATE_GROUPS_MEMBERS_FAILURE:
      return Object.assign({}, state, action.payload);
    case FETCH_NOT_GROUPS_MEMBERS_START:
      return Object.assign({}, state, action.payload);
    case FETCH_NOT_GROUPS_MEMBERS_SUCCESS:
      return Object.assign({}, state, action.payload);
    case FETCH_NOT_GROUPS_MEMBERS_FAILURE:
      return Object.assign({}, state, action.payload);
    default:
  }
  return state;
}
