import React, { Component } from "react";

import { Container, Row, Col } from "reactstrap";
import "../../assets/css/tabledesign.css";
class invoicePrint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 0,
    };
  }
  componentDidMount() {
    this.setState({ total: 0 });
    let total = 0;
    for (let j = 0; j < this.props.data.invoice_data.doctor_data.length; j++) {
      total =
        total +
        (this.props.data.invoice_data.doctor_data[j].unit_price * (20 / 100) +
          this.props.data.invoice_data.doctor_data[j].unit_price *
            (20 / 100) *
            (10 / 100));
    }
    this.setState({ total: total });
    // console.log("total",total)
  }
  formatter(number) {
    number = parseInt(number)
    return new Intl.NumberFormat("en", {
      style: "currency",
      currency: "JPY",
    }).format(number);
  }
  render() {
    return (
      <div style={{ padding: "10%", paddingTop: "10%" }}>
        {/* <Header /> */}{" "}
        {/* <div className="pb-6 pt-2 pt-md-4">
          <h1> &nbsp;</h1>
        </div> */}
        <Container
          className="mt--6"
          fluid
          style={{
            backgroundColor: "white",
            color: "black",
            fontFamily: "Arial, Helvetica, sans-serif",
          }}
        >
          <Row style={{ marginTop: "30px" }}>
            <Col style={{ marginTop: "50px" }}>
              {/* <p>
                {" "}
                <span>医療法人あいうえ会</span> &nbsp;<span>様</span>{" "}
              </p> */}
              <p>{this.props.data.hospital_name} 様</p>
            </Col>
            <Col style={{ marginTop: "50px" }}>
              {" "}
              <p style={{ textAlign: "right" }}>
                <span>
                  {this.props.hos_data.month == 12
                    ? parseInt(this.props.hos_data.year) + 1
                    : this.props.hos_data.year}
                </span>
                &nbsp;<span>年</span>
                &nbsp;
                <span>
                  {this.props.hos_data.month == 12
                    ? "01"
                    : parseInt(this.props.hos_data.month) + 1}
                </span>
                &nbsp;
                <span>月</span>&nbsp;<span>01</span>&nbsp;<span>日</span>
              </p>
              <p style={{ textAlign: "right" }}>
                株式会社 Diversity & Decisions
              </p>
              <p style={{ textAlign: "right" }}>東京都江戸川区本一色1-12-1</p>
              <p style={{ textAlign: "right" }}>電話：03-5879-3183</p>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "25",
                  marginTop: "20px",
                }}
              >
                請求書送付のお知らせ
              </p>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <p>謹啓</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>日頃より弊社をお引き立て賜り、誠に有難うございます。</p>
            </Col>
          </Row>

          <Row>
            <Col>
              <p>
                {this.props.hos_data.year}年{this.props.hos_data.month}月 &nbsp;
                の業務委託料の請求書をお送りします。
              </p>
            </Col>

            {/* <Col>
              <p>の業務委託料の請求書をお送りします。</p>
            </Col> */}
          </Row>
          <br />

          <Row>
            <Col>
              <p>ご査収くださいませ。</p>
            </Col>
          </Row>

          <Row>
            <Col style={{ textAlign: "right" }}>
              <p> 敬白</p>
            </Col>
          </Row>
          <Row>
            <Col style={{ textAlign: "center" }}>
              <p>記</p>
            </Col>
          </Row>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </Container>
        {/* <br /> */}
        <Container
          className="mt--6"
          fluid
          style={{
            backgroundColor: "white",
            color: "black",
            fontFamily: "Arial, Helvetica, sans-serif",
          }}
        >
          <Row className="align-items-center">
            <Col>
              <p style={{ textAlign: "center", fontSize: "25" }}>請求書</p>
            </Col>
          </Row>
          {/* <br /> */}
          <Row style={{ border: "2px solid black" }}>
            <Col style={{ borderRight: "2px solid black" }}>
              {/* <Row>&nbsp;</Row>
              <Row>&nbsp;</Row> */}

              <Row>
                <Col xs={12}>
                  {" "}
                  <p>{this.props.data.hospital_name}&nbsp;様</p><p></p>
                </Col>
                {/* <Col xs={3}>
                  <p>◆{this.props.data.invoice_data.num_of_doctor}院</p>
                </Col> */}
                {/* <Col xs={3}>
                  <p>様</p>
                </Col> */}
              </Row>
            </Col>
            <Col>
              <p style={{ textAlign: "right" }}>
                <span>
                  {this.props.hos_data.month == 12
                    ? parseInt(this.props.hos_data.year) + 1
                    : this.props.hos_data.year}
                </span>
                &nbsp;<span>年</span>
                &nbsp;
                <span>
                  {this.props.hos_data.month == 12
                    ? 1
                    : parseInt(this.props.hos_data.month) + 1}
                </span>
                &nbsp;
                <span>月</span>&nbsp;<span>01</span>
                &nbsp;<span>日</span>
              </p>
              <div style={{ paddingBottom: "0px" }}>
                <p>株式会社 Diversity & Decisions</p>
                <p>
                  {/* 住所: {this.props.data.hospital_address_line1},
                  {this.props.data.hospital_city} */}
                  住所: 東京都江戸川区本一色1-12-1
                </p>
                <p>電話 : 03-5879-3183</p>
                {/* <p>電話 : {this.props.data.hospital_phone}</p> */}
              </div>
            </Col>
          </Row>
          {/* <br/> */}
          <Row>
            <Col>
              <p> 毎度、お引き立てにあずかり有難うございます。 </p>
              <p> 下記の通りご請求申し上げますので、ご査収ください。 </p>
              {/* <p style={{ textAlign: "right" }}>
                ＊太線内の金額をお振込みください。
              </p> */}
            </Col>
          </Row>
          <table style={{ width: "100%", marginTop: "20px" }}>
            <tr style={{ border: "1px solid black !important" }}>
              {/* <td className="trdesign" style={{ width: "15%" }}>
                日付
              </td> */}
              <td className="trdesign" style={{ width: "5%" }}>
                No.
              </td>
              <td className="trdesign" style={{ width: "30%" }}>
                勤務日
              </td>
              <td className="trdesign" style={{ width: "15%" }}>
                医師名
              </td>
              <td className="trdesign" style={{ width: "15%" }}>
                医師支払額
              </td>
              <td className="trdesign" style={{ width: "10%" }}>
                委託料
              </td>
              <td className="trdesign" style={{ width: "10%" }}>
                消費税
              </td>
              <td className="trdesign" style={{ width: "15%" }}>
                ご請求額
              </td>
            </tr>
            {this.props.data != ""
              ? this.props.data.invoice_data.doctor_data.map((item, i) => (
                  <tr style={{ border: "1px solid black !important" }}>
                    {/* <td className="trdesign" style={{ width: "15%" }}>
                      {" "}
                      {this.props.hos_data.month == 12 ? (
                        <p>
                          {this.props.hos_data.year + 1}年<br />
                          {this.props.hos_data.year + 1}-01-01
                        </p>
                      ) : (
                        <p>
                          {this.props.hos_data.year}年<br />
                          {this.props.hos_data.year}-
                          {parseInt(this.props.hos_data.month) + 1}-01
                        </p>
                      )}
                    </td> */}
                    <td className="trdesign" style={{ width: "5%" }}>
                      {" "}
                      {i + 1}
                    </td>
                    <td className="trdesign" style={{ width: "30%" }}>
                      {" "}
                      {item.dates.length === 4 ? (
                        <>
                          {this.props.hos_data.month}月{item.dates[0]}日({item.dates[1]}) -
                          {this.props.hos_data.month}月
                          {item.dates[2]}日({item.dates[3]})
                        </>
                      ) : (
                        <>
                          {/* {this.props.hos_data.month}月{item.dates[0]}日 */}
                          {'NG'}
                        </>
                      )}
                    </td>
                    <td className="trdesign" style={{ width: "15%" }}>
                      {item.last_name}&nbsp;{item.first_name}
                    </td>
                    <td className="trdesign" style={{ width: "15%" }}>
                      {this.formatter(item.unit_price)}
                    </td>
                    <td className="trdesign" style={{ width: "10%" }}>
                      {this.formatter(item.unit_price * (20 / 100))}{" "}
                    </td>
                    <td className="trdesign" style={{ width: "10%" }}>
                      {this.formatter(
                        item.unit_price * (20 / 100) * (10 / 100)
                      )}
                    </td>
                    <td className="trdesign" style={{ width: "15%" }}>
                      {this.formatter(
                        item.unit_price * (20 / 100) +
                          item.unit_price * (20 / 100) * (10 / 100)
                      )}
                    </td>
                  </tr>
                ))
              : null}

            <tr>
              {/* <td style={{ width: "15%" }}>&nbsp;</td> */}
              <td style={{ width: "5%" }}>&nbsp;</td>
              <td style={{ width: "30%" }}>&nbsp;</td>
              <td style={{ width: "15%" }}>&nbsp;</td>
              <td style={{ width: "10%" }}>&nbsp;</td>
              {/* <td style={{width:"10%"}}>&nbsp;</td> */}
              <td className="trdesign" style={{ width: "25%" }} colspan="2">
                ご請求総額
              </td>
              <td className="trdesign" style={{ width: "15%" }}>
                {/* {" "}
                ¥&nbsp;{this.state?.total} */}
                {this.formatter(this.state?.total)}
              </td>
            </tr>
          </table>
          {/* <Row style={{ border: "2px solid black" }}>
            <Col style={{ borderRight: "2px solid black" }} xs={1}>
              日付
            </Col>
            <Col style={{ borderRight: "2px solid black" }} xs={1}>
              No.
            </Col>
            <Col style={{ borderRight: "2px solid black" }} xs={3}>
             勤務日
            </Col>
            <Col style={{ borderRight: "2px solid black" }} xs={2}>
             医師名
            </Col>
            <Col style={{ borderRight: "2px solid black" }} xs={1}>
            医師への給与
            </Col>
            <Col style={{ borderRight: "2px solid black" }} xs={1}>
            委託料
            </Col>
            <Col style={{ borderRight: "2px solid black" }} xs={1}>
            消費税
            </Col>
            <Col
              style={{ width:"20%" }}
              xs={2}
            >
              ご請求額
            </Col>
          </Row>
          {this.props.data != ""
            ? this.props.data.invoice_data.doctor_data.map((item, i) => (
                <Row style={{ border: "2px solid black", borderTop: "0px" }}>
                  <Col style={{ borderRight: "2px solid black" }} xs={1}>
                    {this.props.hos_data.month==12?(
                    <p>
                      {this.props.hos_data.year+1}年<br/>
                      {this.props.hos_data.year+1}-01-01
                    </p>

                    ):(
                      <p>
                      {this.props.hos_data.year}年<br/>
                      {this.props.hos_data.year}-{this.props.hos_data.month+1}-01
                    </p>
                    )

                    }
                    
                   
                  </Col>

                  <Col style={{ borderRight: "2px solid black" }} xs={1}>
                    <p>{i + 1}</p>
                  </Col>

                  <Col style={{ borderRight: "2px solid black" }} xs={3}>
                    {
                      item.dates.length > 1?(
                        <>
                        {this.props.hos_data.month}月{item.dates[0]}日-{this.props.hos_data.month}月{item.dates[item.dates.length-1]}日
                        </>
                      ):(
                        <>
                        {this.props.hos_data.month}月{item.dates[0]}日
                        </>
                      )
                    }
                  </Col>
                  <Col style={{ borderRight: "2px solid black" }} xs={2}>
                    <p>
                      {item.last_name}&nbsp;{item.first_name}
                    </p>
                  </Col>
                  <Col style={{ borderRight: "2px solid black" }} xs={1}>
                    <p>¥ {item.unit_price} </p>
                  </Col>
                  <Col style={{ borderRight: "2px solid black" }} xs={1}>
                    ¥{" "}
                    {item.unit_price *
                      (20 / 100)}{" "}
                  </Col>
                  <Col style={{ borderRight: "2px solid black" ,fontSize:"7px"}} xs={1}>
                    <p>
                      ¥{" "}
                      {item.unit_price *
                        (20 / 100) *
                        (10 / 100)}
                    </p>
                  
                  </Col>
                  <Col  xs={2}>
                    ¥{" "}
                    {item.unit_price *
                      (20 / 100) +
                      item.unit_price *
                        (20 / 100) *
                        (10 / 100)}{" "}
                  </Col>
                </Row>
              ))
            : null}
                      <Row >
            <Col  xs={1}>
            
            </Col>
            <Col  xs={1}>
             
            </Col>
            <Col  xs={3}>
             
            </Col>
            <Col  xs={2}>
           
            </Col>
            <Col style={{ borderRight: "2px solid black" }}  xs={1}>
              &nbsp;
             
            </Col>
            
            <Col  style={{ borderRight:"2px solid black",borderBottom:"2px solid black" }}   xs={2}>
            ご請求額総額
            </Col>
            <Col
             style={{ borderRight:"2px solid black",borderBottom:"2px solid black" }}
              xs={2}
            >
             ¥&nbsp;{this.state?.total}
            </Col>
          </Row> */}
          <br />
          <Row>
            <Col>
              <p style={{ textAlign: "left" }}>
                ご請求総額を以下口座にお振込みください。
              </p>
            </Col>
          </Row>

          <Row style={{ border: "2px solid black" }}>
            <Col>
              <Row>
                <Col>
                  <div style={{}}>
                    <p> 銀行名：三菱UFJ銀行(0005) </p>
                    <p> 口座種別：普通口座</p>
                    <p> 口座名義：株式会社 Diversity & Decisions</p>
                  </div>
                </Col>
                <Col>
                  <div style={{}}>
                    <p>支店名：銀座支店(325)</p>
                    <p>口座番号：0219325</p>
                    <p>口座名義カナ：ｶ)ﾀﾞｲﾊﾞｰｼﾃｨｱﾝﾄﾞﾃﾞｨｼｼﾞｮﾝｽﾞ</p>
                    {/* <p>銀行コード ： 0100</p>
                    <p> 支店コード：221 </p> */}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default invoicePrint;
